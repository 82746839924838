/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Модель ответа GET /payments/v1/paypal
 * @export
 * @interface GetPaymentsPaypal200
 */
export interface GetPaymentsPaypal200 {
    /**
     * paypal email или null в случае, если paypal не добавлен
     * @type {string}
     * @memberof GetPaymentsPaypal200
     */
    email: string;
}

/**
 * Check if a given object implements the GetPaymentsPaypal200 interface.
 */
export function instanceOfGetPaymentsPaypal200(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function GetPaymentsPaypal200FromJSON(json: any): GetPaymentsPaypal200 {
    return GetPaymentsPaypal200FromJSONTyped(json, false);
}

export function GetPaymentsPaypal200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentsPaypal200 {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function GetPaymentsPaypal200ToJSON(value?: GetPaymentsPaypal200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

