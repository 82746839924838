/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserNicknameValidate200
 */
export interface GetUserNicknameValidate200 {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserNicknameValidate200
     */
    alreadyTaken: boolean;
}

/**
 * Check if a given object implements the GetUserNicknameValidate200 interface.
 */
export function instanceOfGetUserNicknameValidate200(value: object): boolean {
    if (!('alreadyTaken' in value)) return false;
    return true;
}

export function GetUserNicknameValidate200FromJSON(json: any): GetUserNicknameValidate200 {
    return GetUserNicknameValidate200FromJSONTyped(json, false);
}

export function GetUserNicknameValidate200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserNicknameValidate200 {
    if (json == null) {
        return json;
    }
    return {
        
        'alreadyTaken': json['alreadyTaken'],
    };
}

export function GetUserNicknameValidate200ToJSON(value?: GetUserNicknameValidate200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'alreadyTaken': value['alreadyTaken'],
    };
}

