import { createEvent, createStore, sample } from 'effector';
import { ErrorCode } from '@/shared/api/codegen/demuzo';
import { ClientError } from '@/shared/api/types';
import isEqual from 'lodash/eq';

export type GlobalErrorType =
  | 'AUTHORIZATION_ERROR'
  | 'BLOCK_USER_ACTIONS_ERROR'
  | 'NOTIFICATION_ERROR';
export interface CommonGlobalError extends ClientError {
  code: GlobalErrorType;
  serverCode: ErrorCode | null;
}
export interface BlockUserActionsGlobalError extends CommonGlobalError {
  code: 'BLOCK_USER_ACTIONS_ERROR';
  title: string;
  message: string;
  helpText: string;
}
export interface NotificationGlobalError extends CommonGlobalError {
  code: 'NOTIFICATION_ERROR';
  message: string;
}
export type GlobalError =
  | CommonGlobalError
  | BlockUserActionsGlobalError
  | NotificationGlobalError;

export const addError = createEvent<{ error: GlobalError; id: number }>();
export const resolveErrorById = createEvent<number>();
export const resetErrors = createEvent();
export const $globalErrors = createStore<
  { error: GlobalError; id: number; isResolved: boolean }[]
>([]).reset(resetErrors);

export const lockGlobalError = createEvent<{
  id: number;
  filter: Partial<GlobalError>;
  resolver: (e: GlobalError) => void;
}>();
export const unlockGlobalError = createEvent<number>();
export const $lockedGlobalErrors = createStore<
  Record<
    number,
    { errorFilter: Partial<GlobalError>; resolver: (e: GlobalError) => void }
  >
>({});

$lockedGlobalErrors
  .on(lockGlobalError, (state, payload) => {
    return {
      ...state,
      [payload.id]: { errorFilter: payload.filter, resolver: payload.resolver },
    };
  })
  .on(unlockGlobalError, (state, payload) => {
    const newState = { ...state };
    delete newState[payload];
    return newState;
  });

const addErrorWithFilter = sample({
  clock: addError,
  source: $lockedGlobalErrors,
  fn: (lockedErrors, payload) => {
    const lockedErrorsValues = Object.values(lockedErrors);
    const errorFilters = lockedErrorsValues.filter((errorValue) =>
      isEqual(errorValue.errorFilter, payload.error),
    );
    if (errorFilters.length > 0) {
      errorFilters.forEach(({ resolver }) => {
        resolver(payload.error);
      });
      return null;
    }

    return payload;
  },
});

$globalErrors
  .on(resolveErrorById, (state, id) => {
    return state.map((error) => {
      if (error.id !== id) {
        return error;
      }
      return {
        ...error,
        isResolved: true,
      };
    });
  })
  .on(addErrorWithFilter, (state, payload) => {
    if (!payload) {
      return state;
    }

    return [...state, { ...payload, isResolved: false }];
  });
