/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignStatsEntry
 */
export interface CampaignStatsEntry {
    /**
     * 
     * @type {Date}
     * @memberof CampaignStatsEntry
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatsEntry
     */
    streamCount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatsEntry
     */
    earningsCents: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStatsEntry
     */
    id: number;
}

/**
 * Check if a given object implements the CampaignStatsEntry interface.
 */
export function instanceOfCampaignStatsEntry(value: object): boolean {
    if (!('date' in value)) return false;
    if (!('streamCount' in value)) return false;
    if (!('earningsCents' in value)) return false;
    if (!('id' in value)) return false;
    return true;
}

export function CampaignStatsEntryFromJSON(json: any): CampaignStatsEntry {
    return CampaignStatsEntryFromJSONTyped(json, false);
}

export function CampaignStatsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStatsEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'streamCount': json['streamCount'],
        'earningsCents': json['earningsCents'],
        'id': json['id'],
    };
}

export function CampaignStatsEntryToJSON(value?: CampaignStatsEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': ((value['date']).toISOString().substring(0,10)),
        'streamCount': value['streamCount'],
        'earningsCents': value['earningsCents'],
        'id': value['id'],
    };
}

