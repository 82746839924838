/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { News } from './News';
import {
    NewsFromJSON,
    NewsFromJSONTyped,
    NewsToJSON,
} from './News';

/**
 * 
 * @export
 * @interface GetNews200
 */
export interface GetNews200 {
    /**
     * 
     * @type {Array<News>}
     * @memberof GetNews200
     */
    items: Array<News>;
    /**
     * 
     * @type {number}
     * @memberof GetNews200
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof GetNews200
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof GetNews200
     */
    total: number;
}

/**
 * Check if a given object implements the GetNews200 interface.
 */
export function instanceOfGetNews200(value: object): boolean {
    if (!('items' in value)) return false;
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    if (!('total' in value)) return false;
    return true;
}

export function GetNews200FromJSON(json: any): GetNews200 {
    return GetNews200FromJSONTyped(json, false);
}

export function GetNews200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNews200 {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(NewsFromJSON)),
        'limit': json['limit'],
        'offset': json['offset'],
        'total': json['total'],
    };
}

export function GetNews200ToJSON(value?: GetNews200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(NewsToJSON)),
        'limit': value['limit'],
        'offset': value['offset'],
        'total': value['total'],
    };
}

