/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetWalletV1TransactionsError
 */
export interface GetWalletV1TransactionsError {
    /**
     * 
     * @type {string}
     * @memberof GetWalletV1TransactionsError
     */
    code: GetWalletV1TransactionsErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWalletV1TransactionsError
     */
    message: string;
}


/**
 * @export
 */
export const GetWalletV1TransactionsErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetWalletV1TransactionsErrorCodeEnum = typeof GetWalletV1TransactionsErrorCodeEnum[keyof typeof GetWalletV1TransactionsErrorCodeEnum];


/**
 * Check if a given object implements the GetWalletV1TransactionsError interface.
 */
export function instanceOfGetWalletV1TransactionsError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetWalletV1TransactionsErrorFromJSON(json: any): GetWalletV1TransactionsError {
    return GetWalletV1TransactionsErrorFromJSONTyped(json, false);
}

export function GetWalletV1TransactionsErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWalletV1TransactionsError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetWalletV1TransactionsErrorToJSON(value?: GetWalletV1TransactionsError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

