/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostProductsV1Error
 */
export interface PostProductsV1Error {
    /**
     * 
     * @type {string}
     * @memberof PostProductsV1Error
     */
    code: PostProductsV1ErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostProductsV1Error
     */
    message: string;
}


/**
 * @export
 */
export const PostProductsV1ErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type PostProductsV1ErrorCodeEnum = typeof PostProductsV1ErrorCodeEnum[keyof typeof PostProductsV1ErrorCodeEnum];


/**
 * Check if a given object implements the PostProductsV1Error interface.
 */
export function instanceOfPostProductsV1Error(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function PostProductsV1ErrorFromJSON(json: any): PostProductsV1Error {
    return PostProductsV1ErrorFromJSONTyped(json, false);
}

export function PostProductsV1ErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProductsV1Error {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function PostProductsV1ErrorToJSON(value?: PostProductsV1Error | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

