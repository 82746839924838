/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostFilesDocs200
 */
export interface PostFilesDocs200 {
    /**
     * 
     * @type {string}
     * @memberof PostFilesDocs200
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostFilesDocs200
     */
    url: string;
}

/**
 * Check if a given object implements the PostFilesDocs200 interface.
 */
export function instanceOfPostFilesDocs200(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('url' in value)) return false;
    return true;
}

export function PostFilesDocs200FromJSON(json: any): PostFilesDocs200 {
    return PostFilesDocs200FromJSONTyped(json, false);
}

export function PostFilesDocs200FromJSONTyped(json: any, ignoreDiscriminator: boolean): PostFilesDocs200 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
    };
}

export function PostFilesDocs200ToJSON(value?: PostFilesDocs200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'url': value['url'],
    };
}

