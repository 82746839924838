/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Допустимые медиа типы файлов
 * @export
 */
export const PaymentSystemEnum = {
    paypal: 'paypal',
    xendit: 'xendit'
} as const;
export type PaymentSystemEnum = typeof PaymentSystemEnum[keyof typeof PaymentSystemEnum];


export function instanceOfPaymentSystemEnum(value: any): boolean {
    return Object.values(PaymentSystemEnum).includes(value);
}

export function PaymentSystemEnumFromJSON(json: any): PaymentSystemEnum {
    return PaymentSystemEnumFromJSONTyped(json, false);
}

export function PaymentSystemEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSystemEnum {
    return json as PaymentSystemEnum;
}

export function PaymentSystemEnumToJSON(value?: PaymentSystemEnum | null): any {
    return value as any;
}

