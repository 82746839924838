/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Investor } from './Investor';
import {
    InvestorFromJSON,
    InvestorFromJSONTyped,
    InvestorToJSON,
} from './Investor';

/**
 * 
 * @export
 * @interface GetInvestors200
 */
export interface GetInvestors200 {
    /**
     * 
     * @type {Array<Investor>}
     * @memberof GetInvestors200
     */
    items: Array<Investor>;
    /**
     * 
     * @type {number}
     * @memberof GetInvestors200
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof GetInvestors200
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof GetInvestors200
     */
    total: number;
}

/**
 * Check if a given object implements the GetInvestors200 interface.
 */
export function instanceOfGetInvestors200(value: object): boolean {
    if (!('items' in value)) return false;
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    if (!('total' in value)) return false;
    return true;
}

export function GetInvestors200FromJSON(json: any): GetInvestors200 {
    return GetInvestors200FromJSONTyped(json, false);
}

export function GetInvestors200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInvestors200 {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(InvestorFromJSON)),
        'limit': json['limit'],
        'offset': json['offset'],
        'total': json['total'],
    };
}

export function GetInvestors200ToJSON(value?: GetInvestors200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(InvestorToJSON)),
        'limit': value['limit'],
        'offset': value['offset'],
        'total': value['total'],
    };
}

