/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostWalletV1WithdrawalError
 */
export interface PostWalletV1WithdrawalError {
    /**
     * 
     * @type {string}
     * @memberof PostWalletV1WithdrawalError
     */
    code: PostWalletV1WithdrawalErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostWalletV1WithdrawalError
     */
    message: string;
}


/**
 * @export
 */
export const PostWalletV1WithdrawalErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    KYC_STATUS_IS_NOT_APPROVED: 'KYC_STATUS_IS_NOT_APPROVED'
} as const;
export type PostWalletV1WithdrawalErrorCodeEnum = typeof PostWalletV1WithdrawalErrorCodeEnum[keyof typeof PostWalletV1WithdrawalErrorCodeEnum];


/**
 * Check if a given object implements the PostWalletV1WithdrawalError interface.
 */
export function instanceOfPostWalletV1WithdrawalError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function PostWalletV1WithdrawalErrorFromJSON(json: any): PostWalletV1WithdrawalError {
    return PostWalletV1WithdrawalErrorFromJSONTyped(json, false);
}

export function PostWalletV1WithdrawalErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostWalletV1WithdrawalError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function PostWalletV1WithdrawalErrorToJSON(value?: PostWalletV1WithdrawalError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

