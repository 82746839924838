/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArtistSocialMedia
 */
export interface ArtistSocialMedia {
    /**
     * Ид соцсети, нужен для админки
     * @type {string}
     * @memberof ArtistSocialMedia
     */
    id?: string;
    /**
     * Название соцсети
     * @type {string}
     * @memberof ArtistSocialMedia
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistSocialMedia
     */
    type: ArtistSocialMediaTypeEnum;
    /**
     * Ссылка на соцсеть
     * @type {string}
     * @memberof ArtistSocialMedia
     */
    url: string;
    /**
     * Количество подписчиков
     * @type {string}
     * @memberof ArtistSocialMedia
     */
    followers: string;
}


/**
 * @export
 */
export const ArtistSocialMediaTypeEnum = {
    instagram: 'instagram',
    twitter: 'twitter',
    facebook: 'facebook',
    tiktok: 'tiktok',
    other: 'other',
    website: 'website'
} as const;
export type ArtistSocialMediaTypeEnum = typeof ArtistSocialMediaTypeEnum[keyof typeof ArtistSocialMediaTypeEnum];


/**
 * Check if a given object implements the ArtistSocialMedia interface.
 */
export function instanceOfArtistSocialMedia(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('type' in value)) return false;
    if (!('url' in value)) return false;
    if (!('followers' in value)) return false;
    return true;
}

export function ArtistSocialMediaFromJSON(json: any): ArtistSocialMedia {
    return ArtistSocialMediaFromJSONTyped(json, false);
}

export function ArtistSocialMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtistSocialMedia {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'],
        'type': json['type'],
        'url': json['url'],
        'followers': json['followers'],
    };
}

export function ArtistSocialMediaToJSON(value?: ArtistSocialMedia | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'type': value['type'],
        'url': value['url'],
        'followers': value['followers'],
    };
}

