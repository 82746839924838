/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocialMetrics } from './SocialMetrics';
import {
    SocialMetricsFromJSON,
    SocialMetricsFromJSONTyped,
    SocialMetricsToJSON,
} from './SocialMetrics';

/**
 * Модель артиста
 * @export
 * @interface Artist
 */
export interface Artist {
    /**
     * 
     * @type {string}
     * @memberof Artist
     */
    id: string;
    /**
     * название/имя артиста
     * @type {string}
     * @memberof Artist
     */
    name: string;
    /**
     * массив с urls фотографий артиста
     * @type {string}
     * @memberof Artist
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Artist
     */
    bio: string | null;
    /**
     * 
     * @type {string}
     * @memberof Artist
     */
    primaryGenre: string | null;
    /**
     * 
     * @type {string}
     * @memberof Artist
     */
    secondaryGenre: string | null;
    /**
     * 
     * @type {string}
     * @memberof Artist
     */
    language: string | null;
    /**
     * id артиста-пользователя на сервисе
     * @type {string}
     * @memberof Artist
     */
    userId: string | null;
    /**
     * 
     * @type {SocialMetrics}
     * @memberof Artist
     */
    socialMetrics?: SocialMetrics;
    /**
     * 
     * @type {Date}
     * @memberof Artist
     */
    createDateTime: Date;
}

/**
 * Check if a given object implements the Artist interface.
 */
export function instanceOfArtist(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('avatarUrl' in value)) return false;
    if (!('bio' in value)) return false;
    if (!('primaryGenre' in value)) return false;
    if (!('secondaryGenre' in value)) return false;
    if (!('language' in value)) return false;
    if (!('userId' in value)) return false;
    if (!('createDateTime' in value)) return false;
    return true;
}

export function ArtistFromJSON(json: any): Artist {
    return ArtistFromJSONTyped(json, false);
}

export function ArtistFromJSONTyped(json: any, ignoreDiscriminator: boolean): Artist {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'avatarUrl': json['avatarUrl'],
        'bio': json['bio'],
        'primaryGenre': json['primaryGenre'],
        'secondaryGenre': json['secondaryGenre'],
        'language': json['language'],
        'userId': json['userId'],
        'socialMetrics': json['socialMetrics'] == null ? undefined : SocialMetricsFromJSON(json['socialMetrics']),
        'createDateTime': (new Date(json['createDateTime'])),
    };
}

export function ArtistToJSON(value?: Artist | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'avatarUrl': value['avatarUrl'],
        'bio': value['bio'],
        'primaryGenre': value['primaryGenre'],
        'secondaryGenre': value['secondaryGenre'],
        'language': value['language'],
        'userId': value['userId'],
        'socialMetrics': SocialMetricsToJSON(value['socialMetrics']),
        'createDateTime': ((value['createDateTime']).toISOString()),
    };
}

