/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteNewsV1UserFavoritesError
 */
export interface DeleteNewsV1UserFavoritesError {
    /**
     * 
     * @type {string}
     * @memberof DeleteNewsV1UserFavoritesError
     */
    code: DeleteNewsV1UserFavoritesErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeleteNewsV1UserFavoritesError
     */
    message: string;
}


/**
 * @export
 */
export const DeleteNewsV1UserFavoritesErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type DeleteNewsV1UserFavoritesErrorCodeEnum = typeof DeleteNewsV1UserFavoritesErrorCodeEnum[keyof typeof DeleteNewsV1UserFavoritesErrorCodeEnum];


/**
 * Check if a given object implements the DeleteNewsV1UserFavoritesError interface.
 */
export function instanceOfDeleteNewsV1UserFavoritesError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function DeleteNewsV1UserFavoritesErrorFromJSON(json: any): DeleteNewsV1UserFavoritesError {
    return DeleteNewsV1UserFavoritesErrorFromJSONTyped(json, false);
}

export function DeleteNewsV1UserFavoritesErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteNewsV1UserFavoritesError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function DeleteNewsV1UserFavoritesErrorToJSON(value?: DeleteNewsV1UserFavoritesError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

