/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductTypeEnum } from './ProductTypeEnum';
import {
    ProductTypeEnumFromJSON,
    ProductTypeEnumFromJSONTyped,
    ProductTypeEnumToJSON,
} from './ProductTypeEnum';
import type { Snippet } from './Snippet';
import {
    SnippetFromJSON,
    SnippetFromJSONTyped,
    SnippetToJSON,
} from './Snippet';

/**
 * 
 * @export
 * @interface PostPutProductsRequest
 */
export interface PostPutProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostPutProductsRequest
     */
    artistId: Array<string>;
    /**
     * Описание компании, строка с md-разметкой
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    description: string;
    /**
     * Цели, для которых начат сбор средств для раздела "Funds go for".
     * @type {Array<string>}
     * @memberof PostPutProductsRequest
     */
    crowdfundingGoals: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostPutProductsRequest
     */
    coverUrls: Array<string>;
    /**
     * дата старта краудфайндинга
     * @type {Date}
     * @memberof PostPutProductsRequest
     */
    startDateTime: Date;
    /**
     * дата завершения краудфайндинга
     * @type {Date}
     * @memberof PostPutProductsRequest
     */
    endDateTime: Date;
    /**
     * дата релиза на dsp
     * @type {Date}
     * @memberof PostPutProductsRequest
     */
    releaseDateTime: Date;
    /**
     * дата публикации продукта на площадке Demuzo
     * @type {Date}
     * @memberof PostPutProductsRequest
     */
    publishDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    primaryGenre: string;
    /**
     * 
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    secondaryGenre?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    labelName: string;
    /**
     * Описание преимуществ, получаемых пользователем при покупке акций. Для раздела "What you get".
     * @type {Array<string>}
     * @memberof PostPutProductsRequest
     */
    benefits: Array<string>;
    /**
     * Ссылка на обложку сниппета
     * @type {string}
     * @memberof PostPutProductsRequest
     */
    snippetCoverUrl: string;
    /**
     * массив IDs сниппетов
     * @type {Array<Snippet>}
     * @memberof PostPutProductsRequest
     */
    snippets: Array<Snippet>;
    /**
     * Массив ID документов по продукту.
     * @type {Array<string>}
     * @memberof PostPutProductsRequest
     */
    docs: Array<string>;
    /**
     * True - продукт сохраняется в черновиках, False - продукт отправляется на модерацию.
     * @type {boolean}
     * @memberof PostPutProductsRequest
     */
    draft: boolean;
    /**
     * Содержит ли продукт ненормативный контент
     * @type {boolean}
     * @memberof PostPutProductsRequest
     */
    isExplicitContent: boolean;
    /**
     * 
     * @type {ProductTypeEnum}
     * @memberof PostPutProductsRequest
     */
    productType: ProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PostPutProductsRequest
     */
    trackCount: number;
}

/**
 * Check if a given object implements the PostPutProductsRequest interface.
 */
export function instanceOfPostPutProductsRequest(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('artistId' in value)) return false;
    if (!('description' in value)) return false;
    if (!('crowdfundingGoals' in value)) return false;
    if (!('coverUrls' in value)) return false;
    if (!('startDateTime' in value)) return false;
    if (!('endDateTime' in value)) return false;
    if (!('releaseDateTime' in value)) return false;
    if (!('publishDateTime' in value)) return false;
    if (!('primaryGenre' in value)) return false;
    if (!('language' in value)) return false;
    if (!('labelName' in value)) return false;
    if (!('benefits' in value)) return false;
    if (!('snippetCoverUrl' in value)) return false;
    if (!('snippets' in value)) return false;
    if (!('docs' in value)) return false;
    if (!('draft' in value)) return false;
    if (!('isExplicitContent' in value)) return false;
    if (!('productType' in value)) return false;
    if (!('trackCount' in value)) return false;
    return true;
}

export function PostPutProductsRequestFromJSON(json: any): PostPutProductsRequest {
    return PostPutProductsRequestFromJSONTyped(json, false);
}

export function PostPutProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPutProductsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'artistId': json['artistId'],
        'description': json['description'],
        'crowdfundingGoals': json['crowdfundingGoals'],
        'coverUrls': json['coverUrls'],
        'startDateTime': (new Date(json['startDateTime'])),
        'endDateTime': (new Date(json['endDateTime'])),
        'releaseDateTime': (new Date(json['releaseDateTime'])),
        'publishDateTime': (new Date(json['publishDateTime'])),
        'primaryGenre': json['primaryGenre'],
        'secondaryGenre': json['secondaryGenre'] == null ? undefined : json['secondaryGenre'],
        'language': json['language'],
        'labelName': json['labelName'],
        'benefits': json['benefits'],
        'snippetCoverUrl': json['snippetCoverUrl'],
        'snippets': ((json['snippets'] as Array<any>).map(SnippetFromJSON)),
        'docs': json['docs'],
        'draft': json['draft'],
        'isExplicitContent': json['isExplicitContent'],
        'productType': ProductTypeEnumFromJSON(json['productType']),
        'trackCount': json['trackCount'],
    };
}

export function PostPutProductsRequestToJSON(value?: PostPutProductsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'artistId': value['artistId'],
        'description': value['description'],
        'crowdfundingGoals': value['crowdfundingGoals'],
        'coverUrls': value['coverUrls'],
        'startDateTime': ((value['startDateTime']).toISOString()),
        'endDateTime': ((value['endDateTime']).toISOString()),
        'releaseDateTime': ((value['releaseDateTime']).toISOString()),
        'publishDateTime': ((value['publishDateTime']).toISOString()),
        'primaryGenre': value['primaryGenre'],
        'secondaryGenre': value['secondaryGenre'],
        'language': value['language'],
        'labelName': value['labelName'],
        'benefits': value['benefits'],
        'snippetCoverUrl': value['snippetCoverUrl'],
        'snippets': ((value['snippets'] as Array<any>).map(SnippetToJSON)),
        'docs': value['docs'],
        'draft': value['draft'],
        'isExplicitContent': value['isExplicitContent'],
        'productType': ProductTypeEnumToJSON(value['productType']),
        'trackCount': value['trackCount'],
    };
}

