/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPaymentsV1PaypalError
 */
export interface GetPaymentsV1PaypalError {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentsV1PaypalError
     */
    code: GetPaymentsV1PaypalErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentsV1PaypalError
     */
    message: string;
}


/**
 * @export
 */
export const GetPaymentsV1PaypalErrorCodeEnum = {
    USER_NOT_FOUND: 'USER_NOT_FOUND'
} as const;
export type GetPaymentsV1PaypalErrorCodeEnum = typeof GetPaymentsV1PaypalErrorCodeEnum[keyof typeof GetPaymentsV1PaypalErrorCodeEnum];


/**
 * Check if a given object implements the GetPaymentsV1PaypalError interface.
 */
export function instanceOfGetPaymentsV1PaypalError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetPaymentsV1PaypalErrorFromJSON(json: any): GetPaymentsV1PaypalError {
    return GetPaymentsV1PaypalErrorFromJSONTyped(json, false);
}

export function GetPaymentsV1PaypalErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentsV1PaypalError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetPaymentsV1PaypalErrorToJSON(value?: GetPaymentsV1PaypalError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

