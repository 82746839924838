/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostAvatar200
 */
export interface PostAvatar200 {
    /**
     * 
     * @type {string}
     * @memberof PostAvatar200
     */
    avatarUrl: string;
}

/**
 * Check if a given object implements the PostAvatar200 interface.
 */
export function instanceOfPostAvatar200(value: object): boolean {
    if (!('avatarUrl' in value)) return false;
    return true;
}

export function PostAvatar200FromJSON(json: any): PostAvatar200 {
    return PostAvatar200FromJSONTyped(json, false);
}

export function PostAvatar200FromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAvatar200 {
    if (json == null) {
        return json;
    }
    return {
        
        'avatarUrl': json['avatarUrl'],
    };
}

export function PostAvatar200ToJSON(value?: PostAvatar200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'avatarUrl': value['avatarUrl'],
    };
}

