/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Модель сниппета
 * @export
 * @interface Snippet
 */
export interface Snippet {
    /**
     * 
     * @type {string}
     * @memberof Snippet
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Snippet
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Snippet
     */
    mediaType: SnippetMediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Snippet
     */
    url: string;
}


/**
 * @export
 */
export const SnippetMediaTypeEnum = {
    audio_mpeg: 'audio/mpeg',
    video_mp4: 'video/mp4'
} as const;
export type SnippetMediaTypeEnum = typeof SnippetMediaTypeEnum[keyof typeof SnippetMediaTypeEnum];


/**
 * Check if a given object implements the Snippet interface.
 */
export function instanceOfSnippet(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('mediaType' in value)) return false;
    if (!('url' in value)) return false;
    return true;
}

export function SnippetFromJSON(json: any): Snippet {
    return SnippetFromJSONTyped(json, false);
}

export function SnippetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Snippet {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'mediaType': json['mediaType'],
        'url': json['url'],
    };
}

export function SnippetToJSON(value?: Snippet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'mediaType': value['mediaType'],
        'url': value['url'],
    };
}

