/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Модель ошибки 404
 * @export
 * @interface NotFoundError404
 */
export interface NotFoundError404 {
    /**
     * 
     * @type {string}
     * @memberof NotFoundError404
     */
    code?: NotFoundError404CodeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotFoundError404
     */
    message?: string;
}


/**
 * @export
 */
export const NotFoundError404CodeEnum = {
    NOT_FOUND: 'NOT_FOUND'
} as const;
export type NotFoundError404CodeEnum = typeof NotFoundError404CodeEnum[keyof typeof NotFoundError404CodeEnum];


/**
 * Check if a given object implements the NotFoundError404 interface.
 */
export function instanceOfNotFoundError404(value: object): boolean {
    return true;
}

export function NotFoundError404FromJSON(json: any): NotFoundError404 {
    return NotFoundError404FromJSONTyped(json, false);
}

export function NotFoundError404FromJSONTyped(json: any, ignoreDiscriminator: boolean): NotFoundError404 {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function NotFoundError404ToJSON(value?: NotFoundError404 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

