/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductsV1Error
 */
export interface GetProductsV1Error {
    /**
     * 
     * @type {string}
     * @memberof GetProductsV1Error
     */
    code: GetProductsV1ErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProductsV1Error
     */
    message: string;
}


/**
 * @export
 */
export const GetProductsV1ErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetProductsV1ErrorCodeEnum = typeof GetProductsV1ErrorCodeEnum[keyof typeof GetProductsV1ErrorCodeEnum];


/**
 * Check if a given object implements the GetProductsV1Error interface.
 */
export function instanceOfGetProductsV1Error(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetProductsV1ErrorFromJSON(json: any): GetProductsV1Error {
    return GetProductsV1ErrorFromJSONTyped(json, false);
}

export function GetProductsV1ErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductsV1Error {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetProductsV1ErrorToJSON(value?: GetProductsV1Error | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

