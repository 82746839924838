/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InternalServerError500,
  PostFilesDocs200,
  PostFilesV1CommonError,
  PostFilesV1DocsError,
} from '../models/index';
import {
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostFilesDocs200FromJSON,
    PostFilesDocs200ToJSON,
    PostFilesV1CommonErrorFromJSON,
    PostFilesV1CommonErrorToJSON,
    PostFilesV1DocsErrorFromJSON,
    PostFilesV1DocsErrorToJSON,
} from '../models/index';

export interface PostFilesV1CommonRequest {
    mimeType: PostFilesV1CommonMimeTypeEnum;
    file: Blob;
}

export interface PostFilesV1DocsRequest {
    file: Blob;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * /files/v1/common
     */
    async postFilesV1CommonRaw(requestParameters: PostFilesV1CommonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['mimeType'] == null) {
            throw new runtime.RequiredError(
                'mimeType',
                'Required parameter "mimeType" was null or undefined when calling postFilesV1Common().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling postFilesV1Common().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['mimeType'] != null) {
            formParams.append('mimeType', requestParameters['mimeType'] as any);
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/files/v1/common`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * /files/v1/common
     */
    async postFilesV1Common(requestParameters: PostFilesV1CommonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.postFilesV1CommonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /files/v1/docs
     */
    async postFilesV1DocsRaw(requestParameters: PostFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostFilesDocs200>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling postFilesV1Docs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/files/v1/docs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFilesDocs200FromJSON(jsonValue));
    }

    /**
     * /files/v1/docs
     */
    async postFilesV1Docs(requestParameters: PostFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostFilesDocs200> {
        const response = await this.postFilesV1DocsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const PostFilesV1CommonMimeTypeEnum = {
    audio_mpeg: 'audio/mpeg',
    video_mp4: 'video/mp4',
    image_jpeg: 'image/jpeg',
    image_png: 'image/png'
} as const;
export type PostFilesV1CommonMimeTypeEnum = typeof PostFilesV1CommonMimeTypeEnum[keyof typeof PostFilesV1CommonMimeTypeEnum];
