/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Модель новости
 * @export
 * @interface News
 */
export interface News {
    /**
     * 
     * @type {string}
     * @memberof News
     */
    id: string;
    /**
     * заголовок новости
     * @type {string}
     * @memberof News
     */
    title: string;
    /**
     * время чтения в минутах
     * @type {number}
     * @memberof News
     */
    duration: number;
    /**
     * превью новости в текстовом формате
     * @type {string}
     * @memberof News
     */
    preview: string | null;
    /**
     * время публикации новости. null указывается в случае, если это драфт новости, который не следует показывать пользователям
     * @type {Date}
     * @memberof News
     */
    publishDate: Date | null;
    /**
     * ссылка на обложку новости или null в случае ее отсутствия
     * @type {string}
     * @memberof News
     */
    coverUrl: string | null;
    /**
     * Если новость касается продукта, указывается ссылка на продукт.
     * @type {string}
     * @memberof News
     */
    productId: string | null;
}

/**
 * Check if a given object implements the News interface.
 */
export function instanceOfNews(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('duration' in value)) return false;
    if (!('preview' in value)) return false;
    if (!('publishDate' in value)) return false;
    if (!('coverUrl' in value)) return false;
    if (!('productId' in value)) return false;
    return true;
}

export function NewsFromJSON(json: any): News {
    return NewsFromJSONTyped(json, false);
}

export function NewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): News {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'duration': json['duration'],
        'preview': json['preview'],
        'publishDate': (json['publishDate'] == null ? null : new Date(json['publishDate'])),
        'coverUrl': json['coverUrl'],
        'productId': json['productId'],
    };
}

export function NewsToJSON(value?: News | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'duration': value['duration'],
        'preview': value['preview'],
        'publishDate': (value['publishDate'] == null ? null : (value['publishDate'] as any).toISOString()),
        'coverUrl': value['coverUrl'],
        'productId': value['productId'],
    };
}

