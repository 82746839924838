/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetKYCV1Status200,
  GetKYCV1SumSubToken200,
  InternalServerError500,
} from '../models/index';
import {
    GetKYCV1Status200FromJSON,
    GetKYCV1Status200ToJSON,
    GetKYCV1SumSubToken200FromJSON,
    GetKYCV1SumSubToken200ToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
} from '../models/index';

/**
 * 
 */
export class KycApi extends runtime.BaseAPI {

    /**
     * Метод возвращает статус проверки KYC пользователя
     * /kyc/v1/status
     */
    async getKycV1StatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetKYCV1Status200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kyc/v1/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKYCV1Status200FromJSON(jsonValue));
    }

    /**
     * Метод возвращает статус проверки KYC пользователя
     * /kyc/v1/status
     */
    async getKycV1Status(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetKYCV1Status200> {
        const response = await this.getKycV1StatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Метод возвращает токен SumSub для WebSDK, а также статус проверки KYC пользователя
     * /kyc/v1/sumsubToken
     */
    async getKycV1SumsubTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetKYCV1SumSubToken200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kyc/v1/sumsubToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKYCV1SumSubToken200FromJSON(jsonValue));
    }

    /**
     * Метод возвращает токен SumSub для WebSDK, а также статус проверки KYC пользователя
     * /kyc/v1/sumsubToken
     */
    async getKycV1SumsubToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetKYCV1SumSubToken200> {
        const response = await this.getKycV1SumsubTokenRaw(initOverrides);
        return await response.value();
    }

}
