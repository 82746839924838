/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArtistPlatforms } from './ArtistPlatforms';
import {
    ArtistPlatformsFromJSON,
    ArtistPlatformsFromJSONTyped,
    ArtistPlatformsToJSON,
} from './ArtistPlatforms';
import type { ArtistSocialMedia } from './ArtistSocialMedia';
import {
    ArtistSocialMediaFromJSON,
    ArtistSocialMediaFromJSONTyped,
    ArtistSocialMediaToJSON,
} from './ArtistSocialMedia';

/**
 * 
 * @export
 * @interface SocialMetrics
 */
export interface SocialMetrics {
    /**
     * Всего прослушиваний на DSP
     * @type {string}
     * @memberof SocialMetrics
     */
    totalStreams: string;
    /**
     * Всего подписчиков в соцсетях
     * @type {string}
     * @memberof SocialMetrics
     */
    totalFollowers: string;
    /**
     * Массив с платформами
     * @type {Array<ArtistPlatforms>}
     * @memberof SocialMetrics
     */
    platforms: Array<ArtistPlatforms> | null;
    /**
     * 
     * @type {Array<ArtistSocialMedia>}
     * @memberof SocialMetrics
     */
    socialMedia: Array<ArtistSocialMedia> | null;
}

/**
 * Check if a given object implements the SocialMetrics interface.
 */
export function instanceOfSocialMetrics(value: object): boolean {
    if (!('totalStreams' in value)) return false;
    if (!('totalFollowers' in value)) return false;
    if (!('platforms' in value)) return false;
    if (!('socialMedia' in value)) return false;
    return true;
}

export function SocialMetricsFromJSON(json: any): SocialMetrics {
    return SocialMetricsFromJSONTyped(json, false);
}

export function SocialMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        'totalStreams': json['totalStreams'],
        'totalFollowers': json['totalFollowers'],
        'platforms': (json['platforms'] == null ? null : (json['platforms'] as Array<any>).map(ArtistPlatformsFromJSON)),
        'socialMedia': (json['socialMedia'] == null ? null : (json['socialMedia'] as Array<any>).map(ArtistSocialMediaFromJSON)),
    };
}

export function SocialMetricsToJSON(value?: SocialMetrics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalStreams': value['totalStreams'],
        'totalFollowers': value['totalFollowers'],
        'platforms': (value['platforms'] == null ? null : (value['platforms'] as Array<any>).map(ArtistPlatformsToJSON)),
        'socialMedia': (value['socialMedia'] == null ? null : (value['socialMedia'] as Array<any>).map(ArtistSocialMediaToJSON)),
    };
}

