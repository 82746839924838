/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductTypeEnum = {
    single: 'single',
    ep: 'ep',
    album: 'album'
} as const;
export type ProductTypeEnum = typeof ProductTypeEnum[keyof typeof ProductTypeEnum];


export function instanceOfProductTypeEnum(value: any): boolean {
    return Object.values(ProductTypeEnum).includes(value);
}

export function ProductTypeEnumFromJSON(json: any): ProductTypeEnum {
    return ProductTypeEnumFromJSONTyped(json, false);
}

export function ProductTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTypeEnum {
    return json as ProductTypeEnum;
}

export function ProductTypeEnumToJSON(value?: ProductTypeEnum | null): any {
    return value as any;
}

