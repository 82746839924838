/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostWithdrawalResponse
 */
export interface PostWithdrawalResponse {
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawalResponse
     */
    withdrawalId: string;
}

/**
 * Check if a given object implements the PostWithdrawalResponse interface.
 */
export function instanceOfPostWithdrawalResponse(value: object): boolean {
    if (!('withdrawalId' in value)) return false;
    return true;
}

export function PostWithdrawalResponseFromJSON(json: any): PostWithdrawalResponse {
    return PostWithdrawalResponseFromJSONTyped(json, false);
}

export function PostWithdrawalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostWithdrawalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'withdrawalId': json['withdrawalId'],
    };
}

export function PostWithdrawalResponseToJSON(value?: PostWithdrawalResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'withdrawalId': value['withdrawalId'],
    };
}

