/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPaymentsPaypal200
 */
export interface PostPaymentsPaypal200 {
    /**
     * email привязанного аккаунта paypal
     * @type {string}
     * @memberof PostPaymentsPaypal200
     */
    email: string;
}

/**
 * Check if a given object implements the PostPaymentsPaypal200 interface.
 */
export function instanceOfPostPaymentsPaypal200(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function PostPaymentsPaypal200FromJSON(json: any): PostPaymentsPaypal200 {
    return PostPaymentsPaypal200FromJSONTyped(json, false);
}

export function PostPaymentsPaypal200FromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentsPaypal200 {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function PostPaymentsPaypal200ToJSON(value?: PostPaymentsPaypal200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

