/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ErrorCode } from './ErrorCode';
import {
    ErrorCodeFromJSON,
    ErrorCodeFromJSONTyped,
    ErrorCodeToJSON,
} from './ErrorCode';

/**
 * Модель ошибок
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {ErrorCode}
     * @memberof ModelError
     */
    code: ErrorCode;
    /**
     * Сообщение (устанавливается сервисов по code ошибки)
     * @type {string}
     * @memberof ModelError
     */
    message: string;
    /**
     * Трейс ошибки (возвращается, если сервис находится в режиме отладки)
     * @type {string}
     * @memberof ModelError
     */
    debug?: string;
    /**
     * Полезная нагрузка
     * @type {{ [key: string]: string; }}
     * @memberof ModelError
     */
    payload?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ModelError interface.
 */
export function instanceOfModelError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function ModelErrorFromJSON(json: any): ModelError {
    return ModelErrorFromJSONTyped(json, false);
}

export function ModelErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': ErrorCodeFromJSON(json['code']),
        'message': json['message'],
        'debug': json['debug'] == null ? undefined : json['debug'],
        'payload': json['payload'] == null ? undefined : json['payload'],
    };
}

export function ModelErrorToJSON(value?: ModelError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': ErrorCodeToJSON(value['code']),
        'message': value['message'],
        'debug': value['debug'],
        'payload': value['payload'],
    };
}

