/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ErrorCode = {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    TIMEOUT_EXCEEDED: 'TIMEOUT_EXCEEDED',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    INVALID_ID_TOKEN: 'INVALID_ID_TOKEN',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    TOKEN_INVALID: 'TOKEN_INVALID',
    TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
    TOKEN_HAS_NOT_SUBJECT_ID: 'TOKEN_HAS_NOT_SUBJECT_ID',
    TOKEN_ROLES_PARSE_FAILED: 'TOKEN_ROLES_PARSE_FAILED',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    USER_BLOCKED: 'USER_BLOCKED',
    NOT_FOUND: 'NOT_FOUND',
    FORBIDDEN: 'FORBIDDEN',
    AMOUNT_LESS_OR_EQUAL_THAN_0: 'AMOUNT_LESS_OR_EQUAL_THAN_0',
    KYC_STATUS_IS_NOT_APPROVED: 'KYC_STATUS_IS_NOT_APPROVED',
    INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
    CAMPAIGN_NOT_IN_PROGRESS: 'CAMPAIGN_NOT_IN_PROGRESS',
    NOT_ENOUGH_CAMPAIGN_SHARES: 'NOT_ENOUGH_CAMPAIGN_SHARES',
    UNAUTHORIZED: 'UNAUTHORIZED',
    TEMPORARY_FORBIDDEN: 'TEMPORARY_FORBIDDEN'
} as const;
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


export function instanceOfErrorCode(value: any): boolean {
    return Object.values(ErrorCode).includes(value);
}

export function ErrorCodeFromJSON(json: any): ErrorCode {
    return ErrorCodeFromJSONTyped(json, false);
}

export function ErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCode {
    return json as ErrorCode;
}

export function ErrorCodeToJSON(value?: ErrorCode | null): any {
    return value as any;
}

