/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutUsersV1ProfilePrivacyError
 */
export interface PutUsersV1ProfilePrivacyError {
    /**
     * 
     * @type {string}
     * @memberof PutUsersV1ProfilePrivacyError
     */
    code: PutUsersV1ProfilePrivacyErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PutUsersV1ProfilePrivacyError
     */
    message: string;
}


/**
 * @export
 */
export const PutUsersV1ProfilePrivacyErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type PutUsersV1ProfilePrivacyErrorCodeEnum = typeof PutUsersV1ProfilePrivacyErrorCodeEnum[keyof typeof PutUsersV1ProfilePrivacyErrorCodeEnum];


/**
 * Check if a given object implements the PutUsersV1ProfilePrivacyError interface.
 */
export function instanceOfPutUsersV1ProfilePrivacyError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function PutUsersV1ProfilePrivacyErrorFromJSON(json: any): PutUsersV1ProfilePrivacyError {
    return PutUsersV1ProfilePrivacyErrorFromJSONTyped(json, false);
}

export function PutUsersV1ProfilePrivacyErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUsersV1ProfilePrivacyError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function PutUsersV1ProfilePrivacyErrorToJSON(value?: PutUsersV1ProfilePrivacyError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

