/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductsV1AllError
 */
export interface GetProductsV1AllError {
    /**
     * 
     * @type {string}
     * @memberof GetProductsV1AllError
     */
    code: GetProductsV1AllErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProductsV1AllError
     */
    message: string;
}


/**
 * @export
 */
export const GetProductsV1AllErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetProductsV1AllErrorCodeEnum = typeof GetProductsV1AllErrorCodeEnum[keyof typeof GetProductsV1AllErrorCodeEnum];


/**
 * Check if a given object implements the GetProductsV1AllError interface.
 */
export function instanceOfGetProductsV1AllError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetProductsV1AllErrorFromJSON(json: any): GetProductsV1AllError {
    return GetProductsV1AllErrorFromJSONTyped(json, false);
}

export function GetProductsV1AllErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductsV1AllError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetProductsV1AllErrorToJSON(value?: GetProductsV1AllError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

