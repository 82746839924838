/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Artist } from './Artist';
import {
    ArtistFromJSON,
    ArtistFromJSONTyped,
    ArtistToJSON,
} from './Artist';
import type { Gender } from './Gender';
import {
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import type { KYCStatus } from './KYCStatus';
import {
    KYCStatusFromJSON,
    KYCStatusFromJSONTyped,
    KYCStatusToJSON,
} from './KYCStatus';
import type { RolesEnum } from './RolesEnum';
import {
    RolesEnumFromJSON,
    RolesEnumFromJSONTyped,
    RolesEnumToJSON,
} from './RolesEnum';

/**
 * 
 * @export
 * @interface GetUserProfile200
 */
export interface GetUserProfile200 {
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    surname: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    nickname: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    avatarUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    phone: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof GetUserProfile200
     */
    gender: Gender | null;
    /**
     * 
     * @type {Date}
     * @memberof GetUserProfile200
     */
    birthDate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfile200
     */
    country: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetUserProfile200
     */
    registrationDate: Date;
    /**
     * Роли пользователя
     * @type {Array<RolesEnum>}
     * @memberof GetUserProfile200
     */
    roles: Array<RolesEnum>;
    /**
     * статус проверки KYC
     * @type {KYCStatus}
     * @memberof GetUserProfile200
     */
    kycStatus: KYCStatus;
    /**
     * был ли профиль пользователя заполнен полностью
     * @type {boolean}
     * @memberof GetUserProfile200
     */
    isFilled: boolean;
    /**
     * является ли профиль приватным (публичный по умолчанию)
     * @type {boolean}
     * @memberof GetUserProfile200
     */
    isPrivate: boolean;
    /**
     * Заполняется если есть профиль артиста связанный с пользователем
     * @type {Array<Artist>}
     * @memberof GetUserProfile200
     */
    artistProfile: Array<Artist> | null;
}

/**
 * Check if a given object implements the GetUserProfile200 interface.
 */
export function instanceOfGetUserProfile200(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('nickname' in value)) return false;
    if (!('avatarUrl' in value)) return false;
    if (!('email' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('gender' in value)) return false;
    if (!('birthDate' in value)) return false;
    if (!('country' in value)) return false;
    if (!('registrationDate' in value)) return false;
    if (!('roles' in value)) return false;
    if (!('kycStatus' in value)) return false;
    if (!('isFilled' in value)) return false;
    if (!('isPrivate' in value)) return false;
    if (!('artistProfile' in value)) return false;
    return true;
}

export function GetUserProfile200FromJSON(json: any): GetUserProfile200 {
    return GetUserProfile200FromJSONTyped(json, false);
}

export function GetUserProfile200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserProfile200 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'nickname': json['nickname'],
        'avatarUrl': json['avatarUrl'],
        'email': json['email'],
        'phone': json['phone'],
        'gender': GenderFromJSON(json['gender']),
        'birthDate': (json['birthDate'] == null ? null : new Date(json['birthDate'])),
        'country': json['country'],
        'registrationDate': (new Date(json['registrationDate'])),
        'roles': ((json['roles'] as Array<any>).map(RolesEnumFromJSON)),
        'kycStatus': KYCStatusFromJSON(json['kycStatus']),
        'isFilled': json['isFilled'],
        'isPrivate': json['isPrivate'],
        'artistProfile': (json['artistProfile'] == null ? null : (json['artistProfile'] as Array<any>).map(ArtistFromJSON)),
    };
}

export function GetUserProfile200ToJSON(value?: GetUserProfile200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'nickname': value['nickname'],
        'avatarUrl': value['avatarUrl'],
        'email': value['email'],
        'phone': value['phone'],
        'gender': GenderToJSON(value['gender']),
        'birthDate': (value['birthDate'] == null ? null : (value['birthDate'] as any).toISOString().substring(0,10)),
        'country': value['country'],
        'registrationDate': ((value['registrationDate']).toISOString()),
        'roles': ((value['roles'] as Array<any>).map(RolesEnumToJSON)),
        'kycStatus': KYCStatusToJSON(value['kycStatus']),
        'isFilled': value['isFilled'],
        'isPrivate': value['isPrivate'],
        'artistProfile': (value['artistProfile'] == null ? null : (value['artistProfile'] as Array<any>).map(ArtistToJSON)),
    };
}

