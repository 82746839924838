/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetUserNicknameValidate200,
  GetUserProfile200,
  GetUsersV1ProfileError,
  InternalServerError500,
  PostAvatar200,
  PostUserPhone200,
  PostUserPhoneRequest,
  PostUserPhoneValidate200,
  PostUserPhoneValidateRequest,
  PostUsersV1AvatarError,
  PostUsersV1PhoneError,
  PostUsersV1PhoneValidateError,
  PutUserProfileResponse200,
  PutUsersV1ProfileError,
  PutUsersV1ProfilePrivacyRequest,
  UpdateProfileRequest,
  UserInfo,
} from '../models/index';
import {
    GetUserNicknameValidate200FromJSON,
    GetUserNicknameValidate200ToJSON,
    GetUserProfile200FromJSON,
    GetUserProfile200ToJSON,
    GetUsersV1ProfileErrorFromJSON,
    GetUsersV1ProfileErrorToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostAvatar200FromJSON,
    PostAvatar200ToJSON,
    PostUserPhone200FromJSON,
    PostUserPhone200ToJSON,
    PostUserPhoneRequestFromJSON,
    PostUserPhoneRequestToJSON,
    PostUserPhoneValidate200FromJSON,
    PostUserPhoneValidate200ToJSON,
    PostUserPhoneValidateRequestFromJSON,
    PostUserPhoneValidateRequestToJSON,
    PostUsersV1AvatarErrorFromJSON,
    PostUsersV1AvatarErrorToJSON,
    PostUsersV1PhoneErrorFromJSON,
    PostUsersV1PhoneErrorToJSON,
    PostUsersV1PhoneValidateErrorFromJSON,
    PostUsersV1PhoneValidateErrorToJSON,
    PutUserProfileResponse200FromJSON,
    PutUserProfileResponse200ToJSON,
    PutUsersV1ProfileErrorFromJSON,
    PutUsersV1ProfileErrorToJSON,
    PutUsersV1ProfilePrivacyRequestFromJSON,
    PutUsersV1ProfilePrivacyRequestToJSON,
    UpdateProfileRequestFromJSON,
    UpdateProfileRequestToJSON,
    UserInfoFromJSON,
    UserInfoToJSON,
} from '../models/index';

export interface GetUsersV1NicknameValidateRequest {
    nickname: string;
}

export interface GetUsersV1UserIdInfoRequest {
    userId: string;
}

export interface PostUsersV1AvatarRequest {
    image: Blob;
}

export interface PostUsersV1PhoneRequest {
    postUserPhoneRequest?: PostUserPhoneRequest;
}

export interface PostUsersV1PhoneValidateRequest {
    postUserPhoneValidateRequest?: PostUserPhoneValidateRequest;
}

export interface PutUsersV1ProfileRequest {
    updateProfileRequest?: UpdateProfileRequest;
}

export interface PutUsersV1ProfilePrivacyOperationRequest {
    putUsersV1ProfilePrivacyRequest?: PutUsersV1ProfilePrivacyRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * /users/v1/avatar
     */
    async deleteUsersV1AvatarRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/avatar`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * /users/v1/avatar
     */
    async deleteUsersV1Avatar(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUsersV1AvatarRaw(initOverrides);
    }

    /**
     * /users/v1/nickname/validate
     */
    async getUsersV1NicknameValidateRaw(requestParameters: GetUsersV1NicknameValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserNicknameValidate200>> {
        if (requestParameters['nickname'] == null) {
            throw new runtime.RequiredError(
                'nickname',
                'Required parameter "nickname" was null or undefined when calling getUsersV1NicknameValidate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['nickname'] != null) {
            queryParameters['nickname'] = requestParameters['nickname'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/nickname/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserNicknameValidate200FromJSON(jsonValue));
    }

    /**
     * /users/v1/nickname/validate
     */
    async getUsersV1NicknameValidate(requestParameters: GetUsersV1NicknameValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserNicknameValidate200> {
        const response = await this.getUsersV1NicknameValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get profile
     * /users/v1/profile
     */
    async getUsersV1ProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserProfile200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserProfile200FromJSON(jsonValue));
    }

    /**
     * Get profile
     * /users/v1/profile
     */
    async getUsersV1Profile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserProfile200> {
        const response = await this.getUsersV1ProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/{userId}/info
     */
    async getUsersV1UserIdInfoRaw(requestParameters: GetUsersV1UserIdInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUsersV1UserIdInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/{userId}/info`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     * /users/v1/{userId}/info
     */
    async getUsersV1UserIdInfo(requestParameters: GetUsersV1UserIdInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfo> {
        const response = await this.getUsersV1UserIdInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/avatar
     */
    async postUsersV1AvatarRaw(requestParameters: PostUsersV1AvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAvatar200>> {
        if (requestParameters['image'] == null) {
            throw new runtime.RequiredError(
                'image',
                'Required parameter "image" was null or undefined when calling postUsersV1Avatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['image'] != null) {
            formParams.append('image', requestParameters['image'] as any);
        }

        const response = await this.request({
            path: `/users/v1/avatar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAvatar200FromJSON(jsonValue));
    }

    /**
     * /users/v1/avatar
     */
    async postUsersV1Avatar(requestParameters: PostUsersV1AvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAvatar200> {
        const response = await this.postUsersV1AvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/phone
     */
    async postUsersV1PhoneRaw(requestParameters: PostUsersV1PhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUserPhone200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUserPhoneRequestToJSON(requestParameters['postUserPhoneRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUserPhone200FromJSON(jsonValue));
    }

    /**
     * /users/v1/phone
     */
    async postUsersV1Phone(requestParameters: PostUsersV1PhoneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUserPhone200> {
        const response = await this.postUsersV1PhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/phone/validate
     */
    async postUsersV1PhoneValidateRaw(requestParameters: PostUsersV1PhoneValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUserPhoneValidate200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/phone/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUserPhoneValidateRequestToJSON(requestParameters['postUserPhoneValidateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUserPhoneValidate200FromJSON(jsonValue));
    }

    /**
     * /users/v1/phone/validate
     */
    async postUsersV1PhoneValidate(requestParameters: PostUsersV1PhoneValidateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUserPhoneValidate200> {
        const response = await this.postUsersV1PhoneValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update profile
     * /users/v1/profile
     */
    async putUsersV1ProfileRaw(requestParameters: PutUsersV1ProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutUserProfileResponse200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileRequestToJSON(requestParameters['updateProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUserProfileResponse200FromJSON(jsonValue));
    }

    /**
     * Update profile
     * /users/v1/profile
     */
    async putUsersV1Profile(requestParameters: PutUsersV1ProfileRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutUserProfileResponse200> {
        const response = await this.putUsersV1ProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/profile/privacy
     */
    async putUsersV1ProfilePrivacyRaw(requestParameters: PutUsersV1ProfilePrivacyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserProfile200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/profile/privacy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUsersV1ProfilePrivacyRequestToJSON(requestParameters['putUsersV1ProfilePrivacyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserProfile200FromJSON(jsonValue));
    }

    /**
     * /users/v1/profile/privacy
     */
    async putUsersV1ProfilePrivacy(requestParameters: PutUsersV1ProfilePrivacyOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserProfile200> {
        const response = await this.putUsersV1ProfilePrivacyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
