/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Gender = {
    male: 'male',
    female: 'female',
    non_binary: 'non_binary',
    prefer_not_to_say: 'prefer_not_to_say'
} as const;
export type Gender = typeof Gender[keyof typeof Gender];


export function instanceOfGender(value: any): boolean {
    return Object.values(Gender).includes(value);
}

export function GenderFromJSON(json: any): Gender {
    return GenderFromJSONTyped(json, false);
}

export function GenderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gender {
    return json as Gender;
}

export function GenderToJSON(value?: Gender | null): any {
    return value as any;
}

