/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostFeeResponse
 */
export interface PostFeeResponse {
    /**
     * Сумма пополнения / вывода в минимальной единице валюты.
     * @type {number}
     * @memberof PostFeeResponse
     */
    requestedAmount: number;
    /**
     * Сумма комиссии на пополнение / вывод в минимальных базовых единицах валюты
     * @type {number}
     * @memberof PostFeeResponse
     */
    commissionsAmount: number;
    /**
     * Сумма requestedAmount + commissionsAmount
     * @type {number}
     * @memberof PostFeeResponse
     */
    totalAmount: number;
}

/**
 * Check if a given object implements the PostFeeResponse interface.
 */
export function instanceOfPostFeeResponse(value: object): boolean {
    if (!('requestedAmount' in value)) return false;
    if (!('commissionsAmount' in value)) return false;
    if (!('totalAmount' in value)) return false;
    return true;
}

export function PostFeeResponseFromJSON(json: any): PostFeeResponse {
    return PostFeeResponseFromJSONTyped(json, false);
}

export function PostFeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostFeeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'requestedAmount': json['requestedAmount'],
        'commissionsAmount': json['commissionsAmount'],
        'totalAmount': json['totalAmount'],
    };
}

export function PostFeeResponseToJSON(value?: PostFeeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requestedAmount': value['requestedAmount'],
        'commissionsAmount': value['commissionsAmount'],
        'totalAmount': value['totalAmount'],
    };
}

