/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostUserPhoneRequest
 */
export interface PostUserPhoneRequest {
    /**
     * телефон в стандарте E.164 https://ru.wikipedia.org/wiki/E.164
     * 
     * @type {string}
     * @memberof PostUserPhoneRequest
     */
    phone: string;
}

/**
 * Check if a given object implements the PostUserPhoneRequest interface.
 */
export function instanceOfPostUserPhoneRequest(value: object): boolean {
    if (!('phone' in value)) return false;
    return true;
}

export function PostUserPhoneRequestFromJSON(json: any): PostUserPhoneRequest {
    return PostUserPhoneRequestFromJSONTyped(json, false);
}

export function PostUserPhoneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUserPhoneRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'phone': json['phone'],
    };
}

export function PostUserPhoneRequestToJSON(value?: PostUserPhoneRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phone': value['phone'],
    };
}

