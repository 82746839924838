/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetBalanceResponse,
  GetUserTransactions200,
  InternalServerError500,
  PostWalletV1WithdrawalError,
  PostWithdrawalRequest,
  PostWithdrawalResponse,
} from '../models/index';
import {
    GetBalanceResponseFromJSON,
    GetBalanceResponseToJSON,
    GetUserTransactions200FromJSON,
    GetUserTransactions200ToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostWalletV1WithdrawalErrorFromJSON,
    PostWalletV1WithdrawalErrorToJSON,
    PostWithdrawalRequestFromJSON,
    PostWithdrawalRequestToJSON,
    PostWithdrawalResponseFromJSON,
    PostWithdrawalResponseToJSON,
} from '../models/index';

export interface GetWalletV1TransactionsRequest {
    limit: number;
    offset: number;
    transactionType?: GetWalletV1TransactionsTransactionTypeEnum;
}

export interface PostWalletV1WithdrawalRequest {
    postWithdrawalRequest?: PostWithdrawalRequest;
}

/**
 * 
 */
export class WalletApi extends runtime.BaseAPI {

    /**
     * /wallet/v1/balance
     */
    async getWalletV1BalanceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBalanceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wallet/v1/balance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBalanceResponseFromJSON(jsonValue));
    }

    /**
     * /wallet/v1/balance
     */
    async getWalletV1Balance(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBalanceResponse> {
        const response = await this.getWalletV1BalanceRaw(initOverrides);
        return await response.value();
    }

    /**
     * /wallet/v1/transactions
     */
    async getWalletV1TransactionsRaw(requestParameters: GetWalletV1TransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserTransactions200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getWalletV1Transactions().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getWalletV1Transactions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['transactionType'] != null) {
            queryParameters['transactionType'] = requestParameters['transactionType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wallet/v1/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTransactions200FromJSON(jsonValue));
    }

    /**
     * /wallet/v1/transactions
     */
    async getWalletV1Transactions(requestParameters: GetWalletV1TransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserTransactions200> {
        const response = await this.getWalletV1TransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /wallet/v1/withdrawal
     */
    async postWalletV1WithdrawalRaw(requestParameters: PostWalletV1WithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostWithdrawalResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wallet/v1/withdrawal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostWithdrawalRequestToJSON(requestParameters['postWithdrawalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostWithdrawalResponseFromJSON(jsonValue));
    }

    /**
     * /wallet/v1/withdrawal
     */
    async postWalletV1Withdrawal(requestParameters: PostWalletV1WithdrawalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostWithdrawalResponse> {
        const response = await this.postWalletV1WithdrawalRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetWalletV1TransactionsTransactionTypeEnum = {
    withdrawal: 'withdrawal',
    refund: 'refund',
    deposit: 'deposit',
    royalty_payment: 'royalty_payment',
    purchase: 'purchase'
} as const;
export type GetWalletV1TransactionsTransactionTypeEnum = typeof GetWalletV1TransactionsTransactionTypeEnum[keyof typeof GetWalletV1TransactionsTransactionTypeEnum];
