/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Artist,
  GetArtistsLableId200,
  InternalServerError500,
  PostArtistsV1Error,
  PostPutArtistsRequest,
  PutArtistsV1ArtistIdError,
} from '../models/index';
import {
    ArtistFromJSON,
    ArtistToJSON,
    GetArtistsLableId200FromJSON,
    GetArtistsLableId200ToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostArtistsV1ErrorFromJSON,
    PostArtistsV1ErrorToJSON,
    PostPutArtistsRequestFromJSON,
    PostPutArtistsRequestToJSON,
    PutArtistsV1ArtistIdErrorFromJSON,
    PutArtistsV1ArtistIdErrorToJSON,
} from '../models/index';

export interface DeleteArtistsV1ArtistIdRequest {
    artistId: string;
}

export interface GetArtistsV1Request {
    limit: number;
    offset: number;
}

export interface GetArtistsV1ArtistIdRequest {
    artistId: string;
}

export interface PostArtistsV1Request {
    postPutArtistsRequest?: PostPutArtistsRequest;
}

export interface PutArtistsV1ArtistIdRequest {
    artistId: string;
    postPutArtistsRequest?: PostPutArtistsRequest;
}

/**
 * 
 */
export class ArtistApi extends runtime.BaseAPI {

    /**
     * удаление артиста
     * /artists/v1/{artistId}
     */
    async deleteArtistsV1ArtistIdRaw(requestParameters: DeleteArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling deleteArtistsV1ArtistId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * удаление артиста
     * /artists/v1/{artistId}
     */
    async deleteArtistsV1ArtistId(requestParameters: DeleteArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteArtistsV1ArtistIdRaw(requestParameters, initOverrides);
    }

    /**
     * Получение артистов текущего лейбла
     * /artists/v1
     */
    async getArtistsV1Raw(requestParameters: GetArtistsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetArtistsLableId200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getArtistsV1().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getArtistsV1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetArtistsLableId200FromJSON(jsonValue));
    }

    /**
     * Получение артистов текущего лейбла
     * /artists/v1
     */
    async getArtistsV1(requestParameters: GetArtistsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetArtistsLableId200> {
        const response = await this.getArtistsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение сущности артиста по ID
     * /artists/v1/{artistId}
     */
    async getArtistsV1ArtistIdRaw(requestParameters: GetArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artist>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling getArtistsV1ArtistId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistFromJSON(jsonValue));
    }

    /**
     * Получение сущности артиста по ID
     * /artists/v1/{artistId}
     */
    async getArtistsV1ArtistId(requestParameters: GetArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artist> {
        const response = await this.getArtistsV1ArtistIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создание артиста
     * /artists/v1
     */
    async postArtistsV1Raw(requestParameters: PostArtistsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artist>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutArtistsRequestToJSON(requestParameters['postPutArtistsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistFromJSON(jsonValue));
    }

    /**
     * Создание артиста
     * /artists/v1
     */
    async postArtistsV1(requestParameters: PostArtistsV1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artist> {
        const response = await this.postArtistsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * апдейт артиста
     * /artists/v1/{artistId}
     */
    async putArtistsV1ArtistIdRaw(requestParameters: PutArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artist>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling putArtistsV1ArtistId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutArtistsRequestToJSON(requestParameters['postPutArtistsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistFromJSON(jsonValue));
    }

    /**
     * апдейт артиста
     * /artists/v1/{artistId}
     */
    async putArtistsV1ArtistId(requestParameters: PutArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artist> {
        const response = await this.putArtistsV1ArtistIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
