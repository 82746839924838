/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocialMetrics } from './SocialMetrics';
import {
    SocialMetricsFromJSON,
    SocialMetricsFromJSONTyped,
    SocialMetricsToJSON,
} from './SocialMetrics';

/**
 * Модель артиста (без id) 
 * @export
 * @interface PostPutArtistsRequest
 */
export interface PostPutArtistsRequest {
    /**
     * название/имя артиста
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    name: string;
    /**
     * массив с urls фотографий артиста
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    bio: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    primaryGenre: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    secondaryGenre: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    language: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostPutArtistsRequest
     */
    userId: string | null;
    /**
     * 
     * @type {SocialMetrics}
     * @memberof PostPutArtistsRequest
     */
    socialMetrics?: SocialMetrics;
}

/**
 * Check if a given object implements the PostPutArtistsRequest interface.
 */
export function instanceOfPostPutArtistsRequest(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('avatarUrl' in value)) return false;
    if (!('bio' in value)) return false;
    if (!('primaryGenre' in value)) return false;
    if (!('secondaryGenre' in value)) return false;
    if (!('language' in value)) return false;
    if (!('userId' in value)) return false;
    return true;
}

export function PostPutArtistsRequestFromJSON(json: any): PostPutArtistsRequest {
    return PostPutArtistsRequestFromJSONTyped(json, false);
}

export function PostPutArtistsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPutArtistsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'avatarUrl': json['avatarUrl'],
        'bio': json['bio'],
        'primaryGenre': json['primaryGenre'],
        'secondaryGenre': json['secondaryGenre'],
        'language': json['language'],
        'userId': json['userId'],
        'socialMetrics': json['socialMetrics'] == null ? undefined : SocialMetricsFromJSON(json['socialMetrics']),
    };
}

export function PostPutArtistsRequestToJSON(value?: PostPutArtistsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'avatarUrl': value['avatarUrl'],
        'bio': value['bio'],
        'primaryGenre': value['primaryGenre'],
        'secondaryGenre': value['secondaryGenre'],
        'language': value['language'],
        'userId': value['userId'],
        'socialMetrics': SocialMetricsToJSON(value['socialMetrics']),
    };
}

