/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * Получить продукты
 * @export
 * @interface GetFeatured200
 */
export interface GetFeatured200 {
    /**
     * 
     * @type {Array<Product>}
     * @memberof GetFeatured200
     */
    items: Array<Product>;
}

/**
 * Check if a given object implements the GetFeatured200 interface.
 */
export function instanceOfGetFeatured200(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function GetFeatured200FromJSON(json: any): GetFeatured200 {
    return GetFeatured200FromJSONTyped(json, false);
}

export function GetFeatured200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFeatured200 {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ProductFromJSON)),
    };
}

export function GetFeatured200ToJSON(value?: GetFeatured200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(ProductToJSON)),
    };
}

