/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostFilesV1CommonError
 */
export interface PostFilesV1CommonError {
    /**
     * 
     * @type {string}
     * @memberof PostFilesV1CommonError
     */
    code: PostFilesV1CommonErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostFilesV1CommonError
     */
    message: string;
}


/**
 * @export
 */
export const PostFilesV1CommonErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type PostFilesV1CommonErrorCodeEnum = typeof PostFilesV1CommonErrorCodeEnum[keyof typeof PostFilesV1CommonErrorCodeEnum];


/**
 * Check if a given object implements the PostFilesV1CommonError interface.
 */
export function instanceOfPostFilesV1CommonError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function PostFilesV1CommonErrorFromJSON(json: any): PostFilesV1CommonError {
    return PostFilesV1CommonErrorFromJSONTyped(json, false);
}

export function PostFilesV1CommonErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostFilesV1CommonError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function PostFilesV1CommonErrorToJSON(value?: PostFilesV1CommonError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

