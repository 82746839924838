/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * Получить продукты
 * @export
 * @interface GetProducts200
 */
export interface GetProducts200 {
    /**
     * 
     * @type {Array<Product>}
     * @memberof GetProducts200
     */
    items: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof GetProducts200
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof GetProducts200
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof GetProducts200
     */
    total: number;
}

/**
 * Check if a given object implements the GetProducts200 interface.
 */
export function instanceOfGetProducts200(value: object): boolean {
    if (!('items' in value)) return false;
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    if (!('total' in value)) return false;
    return true;
}

export function GetProducts200FromJSON(json: any): GetProducts200 {
    return GetProducts200FromJSONTyped(json, false);
}

export function GetProducts200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducts200 {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ProductFromJSON)),
        'limit': json['limit'],
        'offset': json['offset'],
        'total': json['total'],
    };
}

export function GetProducts200ToJSON(value?: GetProducts200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(ProductToJSON)),
        'limit': value['limit'],
        'offset': value['offset'],
        'total': value['total'],
    };
}

