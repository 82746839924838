/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPaymentsPaypalRequest
 */
export interface PostPaymentsPaypalRequest {
    /**
     * code полученный в процессе авторизации через paypal
     * @type {string}
     * @memberof PostPaymentsPaypalRequest
     */
    code: string;
}

/**
 * Check if a given object implements the PostPaymentsPaypalRequest interface.
 */
export function instanceOfPostPaymentsPaypalRequest(value: object): boolean {
    if (!('code' in value)) return false;
    return true;
}

export function PostPaymentsPaypalRequestFromJSON(json: any): PostPaymentsPaypalRequest {
    return PostPaymentsPaypalRequestFromJSONTyped(json, false);
}

export function PostPaymentsPaypalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentsPaypalRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function PostPaymentsPaypalRequestToJSON(value?: PostPaymentsPaypalRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
    };
}

