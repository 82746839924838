/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutUsersV1ProfileError
 */
export interface PutUsersV1ProfileError {
    /**
     * 
     * @type {string}
     * @memberof PutUsersV1ProfileError
     */
    code: PutUsersV1ProfileErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PutUsersV1ProfileError
     */
    message: string;
}


/**
 * @export
 */
export const PutUsersV1ProfileErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type PutUsersV1ProfileErrorCodeEnum = typeof PutUsersV1ProfileErrorCodeEnum[keyof typeof PutUsersV1ProfileErrorCodeEnum];


/**
 * Check if a given object implements the PutUsersV1ProfileError interface.
 */
export function instanceOfPutUsersV1ProfileError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function PutUsersV1ProfileErrorFromJSON(json: any): PutUsersV1ProfileError {
    return PutUsersV1ProfileErrorFromJSONTyped(json, false);
}

export function PutUsersV1ProfileErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUsersV1ProfileError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function PutUsersV1ProfileErrorToJSON(value?: PutUsersV1ProfileError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

