/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * null в приватном профиле
 * @export
 * @interface UserInfoStatistics
 */
export interface UserInfoStatistics {
    /**
     * 
     * @type {number}
     * @memberof UserInfoStatistics
     */
    totalSongs: number;
    /**
     * 
     * @type {number}
     * @memberof UserInfoStatistics
     */
    totalInvested: number;
    /**
     * 
     * @type {number}
     * @memberof UserInfoStatistics
     */
    totalRevenue: number;
    /**
     * 
     * @type {Currency}
     * @memberof UserInfoStatistics
     */
    currency: Currency;
}

/**
 * Check if a given object implements the UserInfoStatistics interface.
 */
export function instanceOfUserInfoStatistics(value: object): boolean {
    if (!('totalSongs' in value)) return false;
    if (!('totalInvested' in value)) return false;
    if (!('totalRevenue' in value)) return false;
    if (!('currency' in value)) return false;
    return true;
}

export function UserInfoStatisticsFromJSON(json: any): UserInfoStatistics {
    return UserInfoStatisticsFromJSONTyped(json, false);
}

export function UserInfoStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoStatistics {
    if (json == null) {
        return json;
    }
    return {
        
        'totalSongs': json['totalSongs'],
        'totalInvested': json['totalInvested'],
        'totalRevenue': json['totalRevenue'],
        'currency': CurrencyFromJSON(json['currency']),
    };
}

export function UserInfoStatisticsToJSON(value?: UserInfoStatistics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalSongs': value['totalSongs'],
        'totalInvested': value['totalInvested'],
        'totalRevenue': value['totalRevenue'],
        'currency': CurrencyToJSON(value['currency']),
    };
}

