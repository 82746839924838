/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetKycV1SumsubTokenError
 */
export interface GetKycV1SumsubTokenError {
    /**
     * 
     * @type {string}
     * @memberof GetKycV1SumsubTokenError
     */
    code: GetKycV1SumsubTokenErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetKycV1SumsubTokenError
     */
    message: string;
}


/**
 * @export
 */
export const GetKycV1SumsubTokenErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetKycV1SumsubTokenErrorCodeEnum = typeof GetKycV1SumsubTokenErrorCodeEnum[keyof typeof GetKycV1SumsubTokenErrorCodeEnum];


/**
 * Check if a given object implements the GetKycV1SumsubTokenError interface.
 */
export function instanceOfGetKycV1SumsubTokenError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetKycV1SumsubTokenErrorFromJSON(json: any): GetKycV1SumsubTokenError {
    return GetKycV1SumsubTokenErrorFromJSONTyped(json, false);
}

export function GetKycV1SumsubTokenErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKycV1SumsubTokenError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetKycV1SumsubTokenErrorToJSON(value?: GetKycV1SumsubTokenError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

