/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostCrowdfundingV1PurchaseRequest
 */
export interface PostCrowdfundingV1PurchaseRequest {
    /**
     * 
     * @type {string}
     * @memberof PostCrowdfundingV1PurchaseRequest
     */
    campaignId: string;
    /**
     * ID продукта, акции которого приобретаются
     * @type {string}
     * @memberof PostCrowdfundingV1PurchaseRequest
     */
    productId: string;
    /**
     * Приобретаемое количество акций
     * @type {number}
     * @memberof PostCrowdfundingV1PurchaseRequest
     */
    purchasedShares: number;
}

/**
 * Check if a given object implements the PostCrowdfundingV1PurchaseRequest interface.
 */
export function instanceOfPostCrowdfundingV1PurchaseRequest(value: object): boolean {
    if (!('campaignId' in value)) return false;
    if (!('productId' in value)) return false;
    if (!('purchasedShares' in value)) return false;
    return true;
}

export function PostCrowdfundingV1PurchaseRequestFromJSON(json: any): PostCrowdfundingV1PurchaseRequest {
    return PostCrowdfundingV1PurchaseRequestFromJSONTyped(json, false);
}

export function PostCrowdfundingV1PurchaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCrowdfundingV1PurchaseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'campaignId': json['campaignId'],
        'productId': json['productId'],
        'purchasedShares': json['purchasedShares'],
    };
}

export function PostCrowdfundingV1PurchaseRequestToJSON(value?: PostCrowdfundingV1PurchaseRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'campaignId': value['campaignId'],
        'productId': value['productId'],
        'purchasedShares': value['purchasedShares'],
    };
}

