/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * Полная модель новости
 * @export
 * @interface FullNews
 */
export interface FullNews {
    /**
     * 
     * @type {string}
     * @memberof FullNews
     */
    id: string;
    /**
     * заголовок новости
     * @type {string}
     * @memberof FullNews
     */
    title: string;
    /**
     * время чтения в минутах
     * @type {number}
     * @memberof FullNews
     */
    duration: number;
    /**
     * превью новости в текстовом формате
     * @type {string}
     * @memberof FullNews
     */
    preview: string | null;
    /**
     * текст новости с html-разметкой
     * @type {string}
     * @memberof FullNews
     */
    text: string;
    /**
     * время публикации новости. null указывается в случае, если это драфт новости, который не следует показывать пользователям
     * @type {Date}
     * @memberof FullNews
     */
    publishDate: Date | null;
    /**
     * ссылка на обложку новости или null в случае ее отсутствия
     * @type {string}
     * @memberof FullNews
     */
    coverUrl: string | null;
    /**
     * 
     * @type {Product}
     * @memberof FullNews
     */
    product?: Product;
}

/**
 * Check if a given object implements the FullNews interface.
 */
export function instanceOfFullNews(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('duration' in value)) return false;
    if (!('preview' in value)) return false;
    if (!('text' in value)) return false;
    if (!('publishDate' in value)) return false;
    if (!('coverUrl' in value)) return false;
    return true;
}

export function FullNewsFromJSON(json: any): FullNews {
    return FullNewsFromJSONTyped(json, false);
}

export function FullNewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullNews {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'duration': json['duration'],
        'preview': json['preview'],
        'text': json['text'],
        'publishDate': (json['publishDate'] == null ? null : new Date(json['publishDate'])),
        'coverUrl': json['coverUrl'],
        'product': json['product'] == null ? undefined : ProductFromJSON(json['product']),
    };
}

export function FullNewsToJSON(value?: FullNews | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'duration': value['duration'],
        'preview': value['preview'],
        'text': value['text'],
        'publishDate': (value['publishDate'] == null ? null : (value['publishDate'] as any).toISOString()),
        'coverUrl': value['coverUrl'],
        'product': ProductToJSON(value['product']),
    };
}

