/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Общая ошибка для запросов
 * @export
 * @interface InternalServerError500
 */
export interface InternalServerError500 {
    /**
     * 
     * @type {string}
     * @memberof InternalServerError500
     */
    code: InternalServerError500CodeEnum;
    /**
     * 
     * @type {string}
     * @memberof InternalServerError500
     */
    message: string;
}


/**
 * @export
 */
export const InternalServerError500CodeEnum = {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR'
} as const;
export type InternalServerError500CodeEnum = typeof InternalServerError500CodeEnum[keyof typeof InternalServerError500CodeEnum];


/**
 * Check if a given object implements the InternalServerError500 interface.
 */
export function instanceOfInternalServerError500(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function InternalServerError500FromJSON(json: any): InternalServerError500 {
    return InternalServerError500FromJSONTyped(json, false);
}

export function InternalServerError500FromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalServerError500 {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function InternalServerError500ToJSON(value?: InternalServerError500 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

