/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignRevenueEntry
 */
export interface CampaignRevenueEntry {
    /**
     * 
     * @type {Date}
     * @memberof CampaignRevenueEntry
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof CampaignRevenueEntry
     */
    revenueCents: number;
}

/**
 * Check if a given object implements the CampaignRevenueEntry interface.
 */
export function instanceOfCampaignRevenueEntry(value: object): boolean {
    if (!('date' in value)) return false;
    if (!('revenueCents' in value)) return false;
    return true;
}

export function CampaignRevenueEntryFromJSON(json: any): CampaignRevenueEntry {
    return CampaignRevenueEntryFromJSONTyped(json, false);
}

export function CampaignRevenueEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignRevenueEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'revenueCents': json['revenueCents'],
    };
}

export function CampaignRevenueEntryToJSON(value?: CampaignRevenueEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': ((value['date']).toISOString().substring(0,10)),
        'revenueCents': value['revenueCents'],
    };
}

