/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostUsersV1PhoneValidateError
 */
export interface PostUsersV1PhoneValidateError {
    /**
     * 
     * @type {string}
     * @memberof PostUsersV1PhoneValidateError
     */
    code: PostUsersV1PhoneValidateErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostUsersV1PhoneValidateError
     */
    message: string;
}


/**
 * @export
 */
export const PostUsersV1PhoneValidateErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type PostUsersV1PhoneValidateErrorCodeEnum = typeof PostUsersV1PhoneValidateErrorCodeEnum[keyof typeof PostUsersV1PhoneValidateErrorCodeEnum];


/**
 * Check if a given object implements the PostUsersV1PhoneValidateError interface.
 */
export function instanceOfPostUsersV1PhoneValidateError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function PostUsersV1PhoneValidateErrorFromJSON(json: any): PostUsersV1PhoneValidateError {
    return PostUsersV1PhoneValidateErrorFromJSONTyped(json, false);
}

export function PostUsersV1PhoneValidateErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUsersV1PhoneValidateError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function PostUsersV1PhoneValidateErrorToJSON(value?: PostUsersV1PhoneValidateError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

