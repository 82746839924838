/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Artist } from './Artist';
import {
    ArtistFromJSON,
    ArtistFromJSONTyped,
    ArtistToJSON,
} from './Artist';
import type { CrowdFundingCampaign } from './CrowdFundingCampaign';
import {
    CrowdFundingCampaignFromJSON,
    CrowdFundingCampaignFromJSONTyped,
    CrowdFundingCampaignToJSON,
} from './CrowdFundingCampaign';
import type { ProductTypeEnum } from './ProductTypeEnum';
import {
    ProductTypeEnumFromJSON,
    ProductTypeEnumFromJSONTyped,
    ProductTypeEnumToJSON,
} from './ProductTypeEnum';
import type { Snippet } from './Snippet';
import {
    SnippetFromJSON,
    SnippetFromJSONTyped,
    SnippetToJSON,
} from './Snippet';

/**
 * Модель продукта
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * Название продукта
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {Array<Artist>}
     * @memberof Product
     */
    artist: Array<Artist>;
    /**
     * Описание компании, строка с md-разметкой
     * @type {string}
     * @memberof Product
     */
    description: string;
    /**
     * URLs обложек продукта
     * @type {Array<string>}
     * @memberof Product
     */
    coverUrls: Array<string>;
    /**
     * 
     * @type {CrowdFundingCampaign}
     * @memberof Product
     */
    crowdFundingCampaign: CrowdFundingCampaign;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    releaseDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    publishDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    primaryGenre: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    secondaryGenre: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    labelName: string;
    /**
     * Описание преимуществ, получаемых пользователем при покупке акций.
     * Для раздела "What you get".
     * @type {Array<string>}
     * @memberof Product
     */
    benefits: Array<string>;
    /**
     * Ссылка на обложку сниппета.
     * @type {string}
     * @memberof Product
     */
    snippetCoverUrl: string;
    /**
     * 
     * @type {Array<Snippet>}
     * @memberof Product
     */
    snippets: Array<Snippet>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status: ProductStatusEnum;
    /**
     * Причина возврата с модерации.
     * Указывается только в том случае, если Product.status == returned.
     * @type {string}
     * @memberof Product
     */
    returnReason: string | null;
    /**
     * находится ли продукт в избранном у текущего пользователя
     * @type {boolean}
     * @memberof Product
     */
    isFavorite: boolean;
    /**
     * Добавлен ли продукт в избранное.
     * По умолчанию значение False.
     * @type {boolean}
     * @memberof Product
     */
    isFeatured: boolean;
    /**
     * Содержит ли продукт ненормативный контент
     * @type {boolean}
     * @memberof Product
     */
    isExplicitContent: boolean;
    /**
     * 
     * @type {ProductTypeEnum}
     * @memberof Product
     */
    productType: ProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    trackCount: number;
}


/**
 * @export
 */
export const ProductStatusEnum = {
    moderation: 'moderation',
    approved: 'approved',
    returned: 'returned',
    draft: 'draft'
} as const;
export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('artist' in value)) return false;
    if (!('description' in value)) return false;
    if (!('coverUrls' in value)) return false;
    if (!('crowdFundingCampaign' in value)) return false;
    if (!('releaseDateTime' in value)) return false;
    if (!('publishDateTime' in value)) return false;
    if (!('primaryGenre' in value)) return false;
    if (!('secondaryGenre' in value)) return false;
    if (!('language' in value)) return false;
    if (!('labelName' in value)) return false;
    if (!('benefits' in value)) return false;
    if (!('snippetCoverUrl' in value)) return false;
    if (!('snippets' in value)) return false;
    if (!('status' in value)) return false;
    if (!('returnReason' in value)) return false;
    if (!('isFavorite' in value)) return false;
    if (!('isFeatured' in value)) return false;
    if (!('isExplicitContent' in value)) return false;
    if (!('productType' in value)) return false;
    if (!('trackCount' in value)) return false;
    return true;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'artist': ((json['artist'] as Array<any>).map(ArtistFromJSON)),
        'description': json['description'],
        'coverUrls': json['coverUrls'],
        'crowdFundingCampaign': CrowdFundingCampaignFromJSON(json['crowdFundingCampaign']),
        'releaseDateTime': (new Date(json['releaseDateTime'])),
        'publishDateTime': (new Date(json['publishDateTime'])),
        'primaryGenre': json['primaryGenre'],
        'secondaryGenre': json['secondaryGenre'],
        'language': json['language'],
        'labelName': json['labelName'],
        'benefits': json['benefits'],
        'snippetCoverUrl': json['snippetCoverUrl'],
        'snippets': ((json['snippets'] as Array<any>).map(SnippetFromJSON)),
        'status': json['status'],
        'returnReason': json['returnReason'],
        'isFavorite': json['isFavorite'],
        'isFeatured': json['isFeatured'],
        'isExplicitContent': json['isExplicitContent'],
        'productType': ProductTypeEnumFromJSON(json['productType']),
        'trackCount': json['trackCount'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'artist': ((value['artist'] as Array<any>).map(ArtistToJSON)),
        'description': value['description'],
        'coverUrls': value['coverUrls'],
        'crowdFundingCampaign': CrowdFundingCampaignToJSON(value['crowdFundingCampaign']),
        'releaseDateTime': ((value['releaseDateTime']).toISOString()),
        'publishDateTime': ((value['publishDateTime']).toISOString()),
        'primaryGenre': value['primaryGenre'],
        'secondaryGenre': value['secondaryGenre'],
        'language': value['language'],
        'labelName': value['labelName'],
        'benefits': value['benefits'],
        'snippetCoverUrl': value['snippetCoverUrl'],
        'snippets': ((value['snippets'] as Array<any>).map(SnippetToJSON)),
        'status': value['status'],
        'returnReason': value['returnReason'],
        'isFavorite': value['isFavorite'],
        'isFeatured': value['isFeatured'],
        'isExplicitContent': value['isExplicitContent'],
        'productType': ProductTypeEnumToJSON(value['productType']),
        'trackCount': value['trackCount'],
    };
}

