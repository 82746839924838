/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetNewsV1ProductProductIdError
 */
export interface GetNewsV1ProductProductIdError {
    /**
     * 
     * @type {string}
     * @memberof GetNewsV1ProductProductIdError
     */
    code: GetNewsV1ProductProductIdErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetNewsV1ProductProductIdError
     */
    message: string;
}


/**
 * @export
 */
export const GetNewsV1ProductProductIdErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetNewsV1ProductProductIdErrorCodeEnum = typeof GetNewsV1ProductProductIdErrorCodeEnum[keyof typeof GetNewsV1ProductProductIdErrorCodeEnum];


/**
 * Check if a given object implements the GetNewsV1ProductProductIdError interface.
 */
export function instanceOfGetNewsV1ProductProductIdError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetNewsV1ProductProductIdErrorFromJSON(json: any): GetNewsV1ProductProductIdError {
    return GetNewsV1ProductProductIdErrorFromJSONTyped(json, false);
}

export function GetNewsV1ProductProductIdErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNewsV1ProductProductIdError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetNewsV1ProductProductIdErrorToJSON(value?: GetNewsV1ProductProductIdError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

