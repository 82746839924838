/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FullNews,
  GetFavoritesNews200,
  GetNews200,
  GetProductNews200,
  GetUserFavorites200,
  InternalServerError500,
  PostDeleteUserFavoriteRequest,
  PostNewsV1UserFavoritesError,
} from '../models/index';
import {
    FullNewsFromJSON,
    FullNewsToJSON,
    GetFavoritesNews200FromJSON,
    GetFavoritesNews200ToJSON,
    GetNews200FromJSON,
    GetNews200ToJSON,
    GetProductNews200FromJSON,
    GetProductNews200ToJSON,
    GetUserFavorites200FromJSON,
    GetUserFavorites200ToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostDeleteUserFavoriteRequestFromJSON,
    PostDeleteUserFavoriteRequestToJSON,
    PostNewsV1UserFavoritesErrorFromJSON,
    PostNewsV1UserFavoritesErrorToJSON,
} from '../models/index';

export interface DeleteNewsV1UserFavoritesRequest {
    productId?: string;
}

export interface GetNewsRequest {
    limit: number;
    offset: number;
}

export interface GetNewsNewsIdRequest {
    newsId: string;
}

export interface GetNewsV1ProductProductIdRequest {
    productId: string;
}

export interface GetNewsV1UserFavoritesUserIdRequest {
    userId: string;
}

export interface GetNewsV1UserNewsUserIdRequest {
    userId: string;
    limit?: number;
    offset?: number;
}

export interface PostNewsV1UserFavoritesRequest {
    postDeleteUserFavoriteRequest?: PostDeleteUserFavoriteRequest;
}

/**
 * 
 */
export class NewsApi extends runtime.BaseAPI {

    /**
     * Исключение продукта из избранного пользователя
     * /news/v1/userFavorites
     */
    async deleteNewsV1UserFavoritesRaw(requestParameters: DeleteNewsV1UserFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userFavorites`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Исключение продукта из избранного пользователя
     * /news/v1/userFavorites
     */
    async deleteNewsV1UserFavorites(requestParameters: DeleteNewsV1UserFavoritesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsV1UserFavoritesRaw(requestParameters, initOverrides);
    }

    /**
     * Метод возвращает список новостей, фильтруя их по publishDate <= текущей
     * /news/v1
     */
    async getNewsRaw(requestParameters: GetNewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNews200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getNews().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getNews().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNews200FromJSON(jsonValue));
    }

    /**
     * Метод возвращает список новостей, фильтруя их по publishDate <= текущей
     * /news/v1
     */
    async getNews(requestParameters: GetNewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNews200> {
        const response = await this.getNewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новости по ID
     * /news/v1/{newsId}
     */
    async getNewsNewsIdRaw(requestParameters: GetNewsNewsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullNews>> {
        if (requestParameters['newsId'] == null) {
            throw new runtime.RequiredError(
                'newsId',
                'Required parameter "newsId" was null or undefined when calling getNewsNewsId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/{newsId}`.replace(`{${"newsId"}}`, encodeURIComponent(String(requestParameters['newsId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullNewsFromJSON(jsonValue));
    }

    /**
     * Получение новости по ID
     * /news/v1/{newsId}
     */
    async getNewsNewsId(requestParameters: GetNewsNewsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullNews> {
        const response = await this.getNewsNewsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новостей по избранному текущего пользователя
     * /news/v1/favorites
     */
    async getNewsV1FavoritesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFavoritesNews200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/favorites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFavoritesNews200FromJSON(jsonValue));
    }

    /**
     * Получение новостей по избранному текущего пользователя
     * /news/v1/favorites
     */
    async getNewsV1Favorites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFavoritesNews200> {
        const response = await this.getNewsV1FavoritesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Получение списка апдейтов по конкретному продукту
     * /news/v1/product/{productId}
     */
    async getNewsV1ProductProductIdRaw(requestParameters: GetNewsV1ProductProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductNews200>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getNewsV1ProductProductId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductNews200FromJSON(jsonValue));
    }

    /**
     * Получение списка апдейтов по конкретному продукту
     * /news/v1/product/{productId}
     */
    async getNewsV1ProductProductId(requestParameters: GetNewsV1ProductProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductNews200> {
        const response = await this.getNewsV1ProductProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новостей по избранным продуктам пользователя
     * /news/v1/userFavorites/{userId}
     */
    async getNewsV1UserFavoritesUserIdRaw(requestParameters: GetNewsV1UserFavoritesUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserFavorites200>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getNewsV1UserFavoritesUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userFavorites/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserFavorites200FromJSON(jsonValue));
    }

    /**
     * Получение новостей по избранным продуктам пользователя
     * /news/v1/userFavorites/{userId}
     */
    async getNewsV1UserFavoritesUserId(requestParameters: GetNewsV1UserFavoritesUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserFavorites200> {
        const response = await this.getNewsV1UserFavoritesUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новостей по всем продуктам пользователя (купленные продукты, продукты в избранном)
     * /news/v1/userNews/{userId}
     */
    async getNewsV1UserNewsUserIdRaw(requestParameters: GetNewsV1UserNewsUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNews200>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getNewsV1UserNewsUserId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userNews/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNews200FromJSON(jsonValue));
    }

    /**
     * Получение новостей по всем продуктам пользователя (купленные продукты, продукты в избранном)
     * /news/v1/userNews/{userId}
     */
    async getNewsV1UserNewsUserId(requestParameters: GetNewsV1UserNewsUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNews200> {
        const response = await this.getNewsV1UserNewsUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавление продукта в избранное пользователя
     * /news/v1/userFavorites
     */
    async postNewsV1UserFavoritesRaw(requestParameters: PostNewsV1UserFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userFavorites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDeleteUserFavoriteRequestToJSON(requestParameters['postDeleteUserFavoriteRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавление продукта в избранное пользователя
     * /news/v1/userFavorites
     */
    async postNewsV1UserFavorites(requestParameters: PostNewsV1UserFavoritesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postNewsV1UserFavoritesRaw(requestParameters, initOverrides);
    }

}
