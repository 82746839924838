/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionInfoProduct
 */
export interface TransactionInfoProduct {
    /**
     * 
     * @type {string}
     * @memberof TransactionInfoProduct
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionInfoProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionInfoProduct
     */
    coverUrl: string | null;
    /**
     * количество купленных лотов для типа транзакции purchase
     * 
     * @type {number}
     * @memberof TransactionInfoProduct
     */
    lots?: number;
    /**
     * доля приобретенных авторских прав для типа транзакции purchase
     * @type {number}
     * @memberof TransactionInfoProduct
     */
    copyrightShare?: number;
}

/**
 * Check if a given object implements the TransactionInfoProduct interface.
 */
export function instanceOfTransactionInfoProduct(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('coverUrl' in value)) return false;
    return true;
}

export function TransactionInfoProductFromJSON(json: any): TransactionInfoProduct {
    return TransactionInfoProductFromJSONTyped(json, false);
}

export function TransactionInfoProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionInfoProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'coverUrl': json['coverUrl'],
        'lots': json['lots'] == null ? undefined : json['lots'],
        'copyrightShare': json['copyrightShare'] == null ? undefined : json['copyrightShare'],
    };
}

export function TransactionInfoProductToJSON(value?: TransactionInfoProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'coverUrl': value['coverUrl'],
        'lots': value['lots'],
        'copyrightShare': value['copyrightShare'],
    };
}

