/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostUserPhoneValidate200
 */
export interface PostUserPhoneValidate200 {
    /**
     * 
     * @type {string}
     * @memberof PostUserPhoneValidate200
     */
    phone: string;
}

/**
 * Check if a given object implements the PostUserPhoneValidate200 interface.
 */
export function instanceOfPostUserPhoneValidate200(value: object): boolean {
    if (!('phone' in value)) return false;
    return true;
}

export function PostUserPhoneValidate200FromJSON(json: any): PostUserPhoneValidate200 {
    return PostUserPhoneValidate200FromJSONTyped(json, false);
}

export function PostUserPhoneValidate200FromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUserPhoneValidate200 {
    if (json == null) {
        return json;
    }
    return {
        
        'phone': json['phone'],
    };
}

export function PostUserPhoneValidate200ToJSON(value?: PostUserPhoneValidate200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phone': value['phone'],
    };
}

