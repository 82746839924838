/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * Вспомогательная модель компании краудфандинга
 * @export
 * @interface CrowdFundingCampaign
 */
export interface CrowdFundingCampaign {
    /**
     * 
     * @type {string}
     * @memberof CrowdFundingCampaign
     */
    id: string;
    /**
     * Цели, для которых начат сбор средств для раздела "Funds go for".
     * @type {Array<string>}
     * @memberof CrowdFundingCampaign
     */
    crowdfundingGoals: Array<string>;
    /**
     * сколько инвестировано конкретным пользователем
     * 
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    userInvested: number | null;
    /**
     * Купленное пользователем количество акций
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    userPurchasedShares: number | null;
    /**
     * Доля авторского права, полученного пользователем после приобретения purchasedShares
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    userShare: number | null;
    /**
     * сколько получено отчислений конкретным пользователем
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    userRevenue: number | null;
    /**
     * 
     * @type {Date}
     * @memberof CrowdFundingCampaign
     */
    startDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof CrowdFundingCampaign
     */
    endDateTime: Date;
    /**
     * 
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    progress: number;
    /**
     * статус краундфандинга (высчитывается автоматически)
     * 
     * finished - если все деньги собраны
     * suspended - приостановлен (из админки)
     * canceled - деньги не собраны за установленный срок
     * @type {string}
     * @memberof CrowdFundingCampaign
     */
    status: CrowdFundingCampaignStatusEnum;
    /**
     * 
     * @type {Currency}
     * @memberof CrowdFundingCampaign
     */
    currency: Currency;
    /**
     * сумма в минимальных базовых единицах (сколько денег надо собрать)
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    successThreshold: number;
    /**
     * стоимость одного лота/акции
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    sharePrice: number;
    /**
     * общее количество выпущенных акций/лотов
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    shareCount: number;
    /**
     * Доля авторского права, выставленная на продажу.
     * Атрибут необходим для подсчёта доли владения пользователем.
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    split: number;
    /**
     * продолжительность соглашения (сколько будет действовать авторское право купившего акции)
     * 
     * если null - то соглашение не имеет срока (бессрочно)
     * @type {string}
     * @memberof CrowdFundingCampaign
     */
    agreementLifetime: string | null;
    /**
     * сколько всего собрано по продукту инвестиций
     * @type {number}
     * @memberof CrowdFundingCampaign
     */
    totalInvested: number;
    /**
     * поле используется для подсчета прогресс бара когда кампания еще не стартовала
     * @type {Date}
     * @memberof CrowdFundingCampaign
     */
    createdAt: Date;
}


/**
 * @export
 */
export const CrowdFundingCampaignStatusEnum = {
    planned: 'planned',
    in_progress: 'in_progress',
    finished: 'finished',
    suspended: 'suspended',
    canceled: 'canceled'
} as const;
export type CrowdFundingCampaignStatusEnum = typeof CrowdFundingCampaignStatusEnum[keyof typeof CrowdFundingCampaignStatusEnum];


/**
 * Check if a given object implements the CrowdFundingCampaign interface.
 */
export function instanceOfCrowdFundingCampaign(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('crowdfundingGoals' in value)) return false;
    if (!('userInvested' in value)) return false;
    if (!('userPurchasedShares' in value)) return false;
    if (!('userShare' in value)) return false;
    if (!('userRevenue' in value)) return false;
    if (!('startDateTime' in value)) return false;
    if (!('endDateTime' in value)) return false;
    if (!('progress' in value)) return false;
    if (!('status' in value)) return false;
    if (!('currency' in value)) return false;
    if (!('successThreshold' in value)) return false;
    if (!('sharePrice' in value)) return false;
    if (!('shareCount' in value)) return false;
    if (!('split' in value)) return false;
    if (!('agreementLifetime' in value)) return false;
    if (!('totalInvested' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function CrowdFundingCampaignFromJSON(json: any): CrowdFundingCampaign {
    return CrowdFundingCampaignFromJSONTyped(json, false);
}

export function CrowdFundingCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrowdFundingCampaign {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'crowdfundingGoals': json['crowdfundingGoals'],
        'userInvested': json['userInvested'],
        'userPurchasedShares': json['userPurchasedShares'],
        'userShare': json['userShare'],
        'userRevenue': json['userRevenue'],
        'startDateTime': (new Date(json['startDateTime'])),
        'endDateTime': (new Date(json['endDateTime'])),
        'progress': json['progress'],
        'status': json['status'],
        'currency': CurrencyFromJSON(json['currency']),
        'successThreshold': json['successThreshold'],
        'sharePrice': json['sharePrice'],
        'shareCount': json['shareCount'],
        'split': json['split'],
        'agreementLifetime': json['agreementLifetime'],
        'totalInvested': json['totalInvested'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CrowdFundingCampaignToJSON(value?: CrowdFundingCampaign | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'crowdfundingGoals': value['crowdfundingGoals'],
        'userInvested': value['userInvested'],
        'userPurchasedShares': value['userPurchasedShares'],
        'userShare': value['userShare'],
        'userRevenue': value['userRevenue'],
        'startDateTime': ((value['startDateTime']).toISOString()),
        'endDateTime': ((value['endDateTime']).toISOString()),
        'progress': value['progress'],
        'status': value['status'],
        'currency': CurrencyToJSON(value['currency']),
        'successThreshold': value['successThreshold'],
        'sharePrice': value['sharePrice'],
        'shareCount': value['shareCount'],
        'split': value['split'],
        'agreementLifetime': value['agreementLifetime'],
        'totalInvested': value['totalInvested'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

