/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Docs
 */
export interface Docs {
    /**
     * 
     * @type {string}
     * @memberof Docs
     */
    id: string;
    /**
     * Имя файла
     * @type {string}
     * @memberof Docs
     */
    title: string;
}

/**
 * Check if a given object implements the Docs interface.
 */
export function instanceOfDocs(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function DocsFromJSON(json: any): Docs {
    return DocsFromJSONTyped(json, false);
}

export function DocsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Docs {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
    };
}

export function DocsToJSON(value?: Docs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
    };
}

