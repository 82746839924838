/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostUserPhone200
 */
export interface PostUserPhone200 {
    /**
     * идентефикатор попытки подтверждения номера телефона
     * @type {string}
     * @memberof PostUserPhone200
     */
    attemptId: string;
}

/**
 * Check if a given object implements the PostUserPhone200 interface.
 */
export function instanceOfPostUserPhone200(value: object): boolean {
    if (!('attemptId' in value)) return false;
    return true;
}

export function PostUserPhone200FromJSON(json: any): PostUserPhone200 {
    return PostUserPhone200FromJSONTyped(json, false);
}

export function PostUserPhone200FromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUserPhone200 {
    if (json == null) {
        return json;
    }
    return {
        
        'attemptId': json['attemptId'],
    };
}

export function PostUserPhone200ToJSON(value?: PostUserPhone200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attemptId': value['attemptId'],
    };
}

