/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KYCStatus } from './KYCStatus';
import {
    KYCStatusFromJSON,
    KYCStatusFromJSONTyped,
    KYCStatusToJSON,
} from './KYCStatus';

/**
 * Модель ответа метода получения статус проверки KYC
 * @export
 * @interface GetKYCV1Status200
 */
export interface GetKYCV1Status200 {
    /**
     * 
     * @type {KYCStatus}
     * @memberof GetKYCV1Status200
     */
    kycStatus: KYCStatus;
}

/**
 * Check if a given object implements the GetKYCV1Status200 interface.
 */
export function instanceOfGetKYCV1Status200(value: object): boolean {
    if (!('kycStatus' in value)) return false;
    return true;
}

export function GetKYCV1Status200FromJSON(json: any): GetKYCV1Status200 {
    return GetKYCV1Status200FromJSONTyped(json, false);
}

export function GetKYCV1Status200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKYCV1Status200 {
    if (json == null) {
        return json;
    }
    return {
        
        'kycStatus': KYCStatusFromJSON(json['kycStatus']),
    };
}

export function GetKYCV1Status200ToJSON(value?: GetKYCV1Status200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kycStatus': KYCStatusToJSON(value['kycStatus']),
    };
}

