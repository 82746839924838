/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KYCStatus } from './KYCStatus';
import {
    KYCStatusFromJSON,
    KYCStatusFromJSONTyped,
    KYCStatusToJSON,
} from './KYCStatus';

/**
 * Модель ответа метода получения токена SumSub
 * @export
 * @interface GetKYCV1SumSubToken200
 */
export interface GetKYCV1SumSubToken200 {
    /**
     * 
     * @type {string}
     * @memberof GetKYCV1SumSubToken200
     */
    appToken: string;
    /**
     * 
     * @type {KYCStatus}
     * @memberof GetKYCV1SumSubToken200
     */
    kycStatus: KYCStatus;
}

/**
 * Check if a given object implements the GetKYCV1SumSubToken200 interface.
 */
export function instanceOfGetKYCV1SumSubToken200(value: object): boolean {
    if (!('appToken' in value)) return false;
    if (!('kycStatus' in value)) return false;
    return true;
}

export function GetKYCV1SumSubToken200FromJSON(json: any): GetKYCV1SumSubToken200 {
    return GetKYCV1SumSubToken200FromJSONTyped(json, false);
}

export function GetKYCV1SumSubToken200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKYCV1SumSubToken200 {
    if (json == null) {
        return json;
    }
    return {
        
        'appToken': json['appToken'],
        'kycStatus': KYCStatusFromJSON(json['kycStatus']),
    };
}

export function GetKYCV1SumSubToken200ToJSON(value?: GetKYCV1SumSubToken200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'appToken': value['appToken'],
        'kycStatus': KYCStatusToJSON(value['kycStatus']),
    };
}

