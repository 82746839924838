/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductsV1ProductIdError
 */
export interface GetProductsV1ProductIdError {
    /**
     * 
     * @type {string}
     * @memberof GetProductsV1ProductIdError
     */
    code: GetProductsV1ProductIdErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProductsV1ProductIdError
     */
    message: string;
}


/**
 * @export
 */
export const GetProductsV1ProductIdErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetProductsV1ProductIdErrorCodeEnum = typeof GetProductsV1ProductIdErrorCodeEnum[keyof typeof GetProductsV1ProductIdErrorCodeEnum];


/**
 * Check if a given object implements the GetProductsV1ProductIdError interface.
 */
export function instanceOfGetProductsV1ProductIdError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetProductsV1ProductIdErrorFromJSON(json: any): GetProductsV1ProductIdError {
    return GetProductsV1ProductIdErrorFromJSONTyped(json, false);
}

export function GetProductsV1ProductIdErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductsV1ProductIdError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetProductsV1ProductIdErrorToJSON(value?: GetProductsV1ProductIdError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

