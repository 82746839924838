/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CampaignRevenueEntry,
  CampaignStats,
  InternalServerError500,
  PostCrowdfundingV1PurchaseRequest,
  Product,
} from '../models/index';
import {
    CampaignRevenueEntryFromJSON,
    CampaignRevenueEntryToJSON,
    CampaignStatsFromJSON,
    CampaignStatsToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostCrowdfundingV1PurchaseRequestFromJSON,
    PostCrowdfundingV1PurchaseRequestToJSON,
    ProductFromJSON,
    ProductToJSON,
} from '../models/index';

export interface GetCrowdfundingV1CampaignRevenuesRequest {
    campaignId: string;
    monthCount: string;
}

export interface GetCrowdfundingV1CampaignStatsRequest {
    campaignId: string;
    monthCount: string;
}

export interface PostCrowdfundingV1PurchaseOperationRequest {
    postCrowdfundingV1PurchaseRequest?: PostCrowdfundingV1PurchaseRequest;
}

/**
 * 
 */
export class CrowdfundingApi extends runtime.BaseAPI {

    /**
     * прибыль по кампании
     * /crowdfunding/v1/campaign/{campaignId}/revenues
     */
    async getCrowdfundingV1CampaignRevenuesRaw(requestParameters: GetCrowdfundingV1CampaignRevenuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CampaignRevenueEntry>>> {
        if (requestParameters['campaignId'] == null) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter "campaignId" was null or undefined when calling getCrowdfundingV1CampaignRevenues().'
            );
        }

        if (requestParameters['monthCount'] == null) {
            throw new runtime.RequiredError(
                'monthCount',
                'Required parameter "monthCount" was null or undefined when calling getCrowdfundingV1CampaignRevenues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['monthCount'] != null) {
            queryParameters['monthCount'] = requestParameters['monthCount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crowdfunding/v1/campaign/{campaignId}/revenues`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters['campaignId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CampaignRevenueEntryFromJSON));
    }

    /**
     * прибыль по кампании
     * /crowdfunding/v1/campaign/{campaignId}/revenues
     */
    async getCrowdfundingV1CampaignRevenues(requestParameters: GetCrowdfundingV1CampaignRevenuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CampaignRevenueEntry>> {
        const response = await this.getCrowdfundingV1CampaignRevenuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * статистика прослушивания по кампании за период
     * /crowdfunding/v1/campaign/{campaignId}/stats
     */
    async getCrowdfundingV1CampaignStatsRaw(requestParameters: GetCrowdfundingV1CampaignStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CampaignStats>> {
        if (requestParameters['campaignId'] == null) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter "campaignId" was null or undefined when calling getCrowdfundingV1CampaignStats().'
            );
        }

        if (requestParameters['monthCount'] == null) {
            throw new runtime.RequiredError(
                'monthCount',
                'Required parameter "monthCount" was null or undefined when calling getCrowdfundingV1CampaignStats().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['monthCount'] != null) {
            queryParameters['monthCount'] = requestParameters['monthCount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crowdfunding/v1/campaign/{campaignId}/stats`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters['campaignId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignStatsFromJSON(jsonValue));
    }

    /**
     * статистика прослушивания по кампании за период
     * /crowdfunding/v1/campaign/{campaignId}/stats
     */
    async getCrowdfundingV1CampaignStats(requestParameters: GetCrowdfundingV1CampaignStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CampaignStats> {
        const response = await this.getCrowdfundingV1CampaignStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /crowdfunding/v1/purchase
     */
    async postCrowdfundingV1PurchaseRaw(requestParameters: PostCrowdfundingV1PurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crowdfunding/v1/purchase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCrowdfundingV1PurchaseRequestToJSON(requestParameters['postCrowdfundingV1PurchaseRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * /crowdfunding/v1/purchase
     */
    async postCrowdfundingV1Purchase(requestParameters: PostCrowdfundingV1PurchaseOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.postCrowdfundingV1PurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
