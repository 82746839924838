/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostDeleteUserFavoriteRequest
 */
export interface PostDeleteUserFavoriteRequest {
    /**
     * product id который нужно добавит или удалить из избранного (в зависимости от метода)
     * @type {string}
     * @memberof PostDeleteUserFavoriteRequest
     */
    productId: string;
}

/**
 * Check if a given object implements the PostDeleteUserFavoriteRequest interface.
 */
export function instanceOfPostDeleteUserFavoriteRequest(value: object): boolean {
    if (!('productId' in value)) return false;
    return true;
}

export function PostDeleteUserFavoriteRequestFromJSON(json: any): PostDeleteUserFavoriteRequest {
    return PostDeleteUserFavoriteRequestFromJSONTyped(json, false);
}

export function PostDeleteUserFavoriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDeleteUserFavoriteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'],
    };
}

export function PostDeleteUserFavoriteRequestToJSON(value?: PostDeleteUserFavoriteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productId': value['productId'],
    };
}

