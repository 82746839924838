/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetNewsV1FavoritesError
 */
export interface GetNewsV1FavoritesError {
    /**
     * 
     * @type {string}
     * @memberof GetNewsV1FavoritesError
     */
    code: GetNewsV1FavoritesErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetNewsV1FavoritesError
     */
    message: string;
}


/**
 * @export
 */
export const GetNewsV1FavoritesErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetNewsV1FavoritesErrorCodeEnum = typeof GetNewsV1FavoritesErrorCodeEnum[keyof typeof GetNewsV1FavoritesErrorCodeEnum];


/**
 * Check if a given object implements the GetNewsV1FavoritesError interface.
 */
export function instanceOfGetNewsV1FavoritesError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetNewsV1FavoritesErrorFromJSON(json: any): GetNewsV1FavoritesError {
    return GetNewsV1FavoritesErrorFromJSONTyped(json, false);
}

export function GetNewsV1FavoritesErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNewsV1FavoritesError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetNewsV1FavoritesErrorToJSON(value?: GetNewsV1FavoritesError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

