/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutUsersV1ProfilePrivacyRequest
 */
export interface PutUsersV1ProfilePrivacyRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PutUsersV1ProfilePrivacyRequest
     */
    isPrivate: boolean;
}

/**
 * Check if a given object implements the PutUsersV1ProfilePrivacyRequest interface.
 */
export function instanceOfPutUsersV1ProfilePrivacyRequest(value: object): boolean {
    if (!('isPrivate' in value)) return false;
    return true;
}

export function PutUsersV1ProfilePrivacyRequestFromJSON(json: any): PutUsersV1ProfilePrivacyRequest {
    return PutUsersV1ProfilePrivacyRequestFromJSONTyped(json, false);
}

export function PutUsersV1ProfilePrivacyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUsersV1ProfilePrivacyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'isPrivate': json['isPrivate'],
    };
}

export function PutUsersV1ProfilePrivacyRequestToJSON(value?: PutUsersV1ProfilePrivacyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isPrivate': value['isPrivate'],
    };
}

