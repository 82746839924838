/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUsersV1UserIdInfoError
 */
export interface GetUsersV1UserIdInfoError {
    /**
     * 
     * @type {string}
     * @memberof GetUsersV1UserIdInfoError
     */
    code: GetUsersV1UserIdInfoErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUsersV1UserIdInfoError
     */
    message: string;
}


/**
 * @export
 */
export const GetUsersV1UserIdInfoErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetUsersV1UserIdInfoErrorCodeEnum = typeof GetUsersV1UserIdInfoErrorCodeEnum[keyof typeof GetUsersV1UserIdInfoErrorCodeEnum];


/**
 * Check if a given object implements the GetUsersV1UserIdInfoError interface.
 */
export function instanceOfGetUsersV1UserIdInfoError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetUsersV1UserIdInfoErrorFromJSON(json: any): GetUsersV1UserIdInfoError {
    return GetUsersV1UserIdInfoErrorFromJSONTyped(json, false);
}

export function GetUsersV1UserIdInfoErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersV1UserIdInfoError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetUsersV1UserIdInfoErrorToJSON(value?: GetUsersV1UserIdInfoError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

