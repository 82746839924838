/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { News } from './News';
import {
    NewsFromJSON,
    NewsFromJSONTyped,
    NewsToJSON,
} from './News';

/**
 * 
 * @export
 * @interface GetFavoritesNews200
 */
export interface GetFavoritesNews200 {
    /**
     * 
     * @type {Array<News>}
     * @memberof GetFavoritesNews200
     */
    items: Array<News>;
}

/**
 * Check if a given object implements the GetFavoritesNews200 interface.
 */
export function instanceOfGetFavoritesNews200(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function GetFavoritesNews200FromJSON(json: any): GetFavoritesNews200 {
    return GetFavoritesNews200FromJSONTyped(json, false);
}

export function GetFavoritesNews200FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFavoritesNews200 {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(NewsFromJSON)),
    };
}

export function GetFavoritesNews200ToJSON(value?: GetFavoritesNews200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(NewsToJSON)),
    };
}

