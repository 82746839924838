/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentSystemEnum } from './PaymentSystemEnum';
import {
    PaymentSystemEnumFromJSON,
    PaymentSystemEnumFromJSONTyped,
    PaymentSystemEnumToJSON,
} from './PaymentSystemEnum';

/**
 * 
 * @export
 * @interface PostPaymentsV1DepositRequest
 */
export interface PostPaymentsV1DepositRequest {
    /**
     * 
     * @type {number}
     * @memberof PostPaymentsV1DepositRequest
     */
    amount: number;
    /**
     * URL для редиректа при успешной оплате в Stripe, необходим для создания checkout session.
     * @type {string}
     * @memberof PostPaymentsV1DepositRequest
     */
    successUrl: string;
    /**
     * URL для редиректа, если пользователь не совершил оплату в Stripe.
     * @type {string}
     * @memberof PostPaymentsV1DepositRequest
     */
    cancelUrl: string;
    /**
     * 
     * @type {PaymentSystemEnum}
     * @memberof PostPaymentsV1DepositRequest
     */
    paymentSystem: PaymentSystemEnum;
}

/**
 * Check if a given object implements the PostPaymentsV1DepositRequest interface.
 */
export function instanceOfPostPaymentsV1DepositRequest(value: object): boolean {
    if (!('amount' in value)) return false;
    if (!('successUrl' in value)) return false;
    if (!('cancelUrl' in value)) return false;
    if (!('paymentSystem' in value)) return false;
    return true;
}

export function PostPaymentsV1DepositRequestFromJSON(json: any): PostPaymentsV1DepositRequest {
    return PostPaymentsV1DepositRequestFromJSONTyped(json, false);
}

export function PostPaymentsV1DepositRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentsV1DepositRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'successUrl': json['successUrl'],
        'cancelUrl': json['cancelUrl'],
        'paymentSystem': PaymentSystemEnumFromJSON(json['paymentSystem']),
    };
}

export function PostPaymentsV1DepositRequestToJSON(value?: PostPaymentsV1DepositRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'successUrl': value['successUrl'],
        'cancelUrl': value['cancelUrl'],
        'paymentSystem': PaymentSystemEnumToJSON(value['paymentSystem']),
    };
}

