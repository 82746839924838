/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetNewsV1UserFavoritesUserIdError
 */
export interface GetNewsV1UserFavoritesUserIdError {
    /**
     * 
     * @type {string}
     * @memberof GetNewsV1UserFavoritesUserIdError
     */
    code: GetNewsV1UserFavoritesUserIdErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetNewsV1UserFavoritesUserIdError
     */
    message: string;
}


/**
 * @export
 */
export const GetNewsV1UserFavoritesUserIdErrorCodeEnum = {
    VALIDATION_ERROR: 'VALIDATION_ERROR'
} as const;
export type GetNewsV1UserFavoritesUserIdErrorCodeEnum = typeof GetNewsV1UserFavoritesUserIdErrorCodeEnum[keyof typeof GetNewsV1UserFavoritesUserIdErrorCodeEnum];


/**
 * Check if a given object implements the GetNewsV1UserFavoritesUserIdError interface.
 */
export function instanceOfGetNewsV1UserFavoritesUserIdError(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function GetNewsV1UserFavoritesUserIdErrorFromJSON(json: any): GetNewsV1UserFavoritesUserIdError {
    return GetNewsV1UserFavoritesUserIdErrorFromJSONTyped(json, false);
}

export function GetNewsV1UserFavoritesUserIdErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNewsV1UserFavoritesUserIdError {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function GetNewsV1UserFavoritesUserIdErrorToJSON(value?: GetNewsV1UserFavoritesUserIdError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

