/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface GetBalanceResponse
 */
export interface GetBalanceResponse {
    /**
     * 
     * @type {number}
     * @memberof GetBalanceResponse
     */
    balance: number;
    /**
     * 
     * @type {Currency}
     * @memberof GetBalanceResponse
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceResponse
     */
    totalRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceResponse
     */
    totalInvested: number;
}

/**
 * Check if a given object implements the GetBalanceResponse interface.
 */
export function instanceOfGetBalanceResponse(value: object): boolean {
    if (!('balance' in value)) return false;
    if (!('currency' in value)) return false;
    if (!('totalRevenue' in value)) return false;
    if (!('totalInvested' in value)) return false;
    return true;
}

export function GetBalanceResponseFromJSON(json: any): GetBalanceResponse {
    return GetBalanceResponseFromJSONTyped(json, false);
}

export function GetBalanceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBalanceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'currency': CurrencyFromJSON(json['currency']),
        'totalRevenue': json['totalRevenue'],
        'totalInvested': json['totalInvested'],
    };
}

export function GetBalanceResponseToJSON(value?: GetBalanceResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'balance': value['balance'],
        'currency': CurrencyToJSON(value['currency']),
        'totalRevenue': value['totalRevenue'],
        'totalInvested': value['totalInvested'],
    };
}

