/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentSystemEnum } from './PaymentSystemEnum';
import {
    PaymentSystemEnumFromJSON,
    PaymentSystemEnumFromJSONTyped,
    PaymentSystemEnumToJSON,
} from './PaymentSystemEnum';

/**
 * 
 * @export
 * @interface PostPaymentsV1FeeRequest
 */
export interface PostPaymentsV1FeeRequest {
    /**
     * Сумма, на которую планируется пополнение кошелька. Указывается в минимальных единицах валюты.
     * @type {number}
     * @memberof PostPaymentsV1FeeRequest
     */
    requestedAmount: number;
    /**
     * Тип операции, для которой рассчитывается комиссия (replenishment - пополнение, withdrawal - вывод)
     * @type {string}
     * @memberof PostPaymentsV1FeeRequest
     */
    operationType: PostPaymentsV1FeeRequestOperationTypeEnum;
    /**
     * 
     * @type {PaymentSystemEnum}
     * @memberof PostPaymentsV1FeeRequest
     */
    paymentSystem: PaymentSystemEnum;
}


/**
 * @export
 */
export const PostPaymentsV1FeeRequestOperationTypeEnum = {
    withdrawal: 'withdrawal',
    replenishment: 'replenishment'
} as const;
export type PostPaymentsV1FeeRequestOperationTypeEnum = typeof PostPaymentsV1FeeRequestOperationTypeEnum[keyof typeof PostPaymentsV1FeeRequestOperationTypeEnum];


/**
 * Check if a given object implements the PostPaymentsV1FeeRequest interface.
 */
export function instanceOfPostPaymentsV1FeeRequest(value: object): boolean {
    if (!('requestedAmount' in value)) return false;
    if (!('operationType' in value)) return false;
    if (!('paymentSystem' in value)) return false;
    return true;
}

export function PostPaymentsV1FeeRequestFromJSON(json: any): PostPaymentsV1FeeRequest {
    return PostPaymentsV1FeeRequestFromJSONTyped(json, false);
}

export function PostPaymentsV1FeeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentsV1FeeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'requestedAmount': json['requestedAmount'],
        'operationType': json['operationType'],
        'paymentSystem': PaymentSystemEnumFromJSON(json['paymentSystem']),
    };
}

export function PostPaymentsV1FeeRequestToJSON(value?: PostPaymentsV1FeeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requestedAmount': value['requestedAmount'],
        'operationType': value['operationType'],
        'paymentSystem': PaymentSystemEnumToJSON(value['paymentSystem']),
    };
}

