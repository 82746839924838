/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Docs,
  GetFeatured200,
  GetProducts200,
  InternalServerError500,
  PostProductsV1Error,
  PostPutProductsRequest,
  Product,
  PutProductsV1ProductIdError,
} from '../models/index';
import {
    DocsFromJSON,
    DocsToJSON,
    GetFeatured200FromJSON,
    GetFeatured200ToJSON,
    GetProducts200FromJSON,
    GetProducts200ToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostProductsV1ErrorFromJSON,
    PostProductsV1ErrorToJSON,
    PostPutProductsRequestFromJSON,
    PostPutProductsRequestToJSON,
    ProductFromJSON,
    ProductToJSON,
    PutProductsV1ProductIdErrorFromJSON,
    PutProductsV1ProductIdErrorToJSON,
} from '../models/index';

export interface DeleteProductsV1Request {
    productId?: string;
}

export interface GetFilesV1DocsRequest {
    productId: string;
}

export interface GetProductV1ArtistProductsRequest {
    artistId: string;
    limit: number;
    offset: number;
}

export interface GetProductsV1Request {
    limit: number;
    offset: number;
}

export interface GetProductsV1AllRequest {
    limit: number;
    offset: number;
}

export interface GetProductsV1ProductIdRequest {
    productId: string;
}

export interface PostProductsV1Request {
    postPutProductsRequest?: PostPutProductsRequest;
}

export interface PutProductsV1ProductIdRequest {
    productId: string;
    postPutProductsRequest?: PostPutProductsRequest;
}

/**
 * 
 */
export class ProductApi extends runtime.BaseAPI {

    /**
     * Удаление продукта, не прошедшего модерацию.  Может быть вызван лейблом после возвращения продукта с модерации для правок.
     * /products/v1
     */
    async deleteProductsV1Raw(requestParameters: DeleteProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление продукта, не прошедшего модерацию.  Может быть вызван лейблом после возвращения продукта с модерации для правок.
     * /products/v1
     */
    async deleteProductsV1(requestParameters: DeleteProductsV1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductsV1Raw(requestParameters, initOverrides);
    }

    /**
     * Получение набора документов из приватного бакета по ID продукта.  Метод необходим для редактирования продукта. 
     * /products/v1/docs
     */
    async getFilesV1DocsRaw(requestParameters: GetFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Docs>>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getFilesV1Docs().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/docs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocsFromJSON));
    }

    /**
     * Получение набора документов из приватного бакета по ID продукта.  Метод необходим для редактирования продукта. 
     * /products/v1/docs
     */
    async getFilesV1Docs(requestParameters: GetFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Docs>> {
        const response = await this.getFilesV1DocsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Метод для получения продуктов артиста.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/artistProducts/{artistId}
     */
    async getProductV1ArtistProductsRaw(requestParameters: GetProductV1ArtistProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling getProductV1ArtistProducts().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getProductV1ArtistProducts().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getProductV1ArtistProducts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/artistProducts/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200FromJSON(jsonValue));
    }

    /**
     * Метод для получения продуктов артиста.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/artistProducts/{artistId}
     */
    async getProductV1ArtistProducts(requestParameters: GetProductV1ArtistProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200> {
        const response = await this.getProductV1ArtistProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Метод для получения рекомендованных продуктов для главной страницы.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/featured
     */
    async getProductV1FeaturedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFeatured200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/featured`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeatured200FromJSON(jsonValue));
    }

    /**
     * Метод для получения рекомендованных продуктов для главной страницы.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/featured
     */
    async getProductV1Featured(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFeatured200> {
        const response = await this.getProductV1FeaturedRaw(initOverrides);
        return await response.value();
    }

    /**
     * список продуктов лейбла
     * /products/v1
     */
    async getProductsV1Raw(requestParameters: GetProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getProductsV1().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getProductsV1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200FromJSON(jsonValue));
    }

    /**
     * список продуктов лейбла
     * /products/v1
     */
    async getProductsV1(requestParameters: GetProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200> {
        const response = await this.getProductsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение всех продуктов отсортированных по publishDate (от новых к старым) для главной страницы. Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     *   /products/v1/all
     */
    async getProductsV1AllRaw(requestParameters: GetProductsV1AllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getProductsV1All().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getProductsV1All().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200FromJSON(jsonValue));
    }

    /**
     * Получение всех продуктов отсортированных по publishDate (от новых к старым) для главной страницы. Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     *   /products/v1/all
     */
    async getProductsV1All(requestParameters: GetProductsV1AllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200> {
        const response = await this.getProductsV1AllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * получение продукта по id (общедоступный метод)
     * /products/v1/{productId}
     */
    async getProductsV1ProductIdRaw(requestParameters: GetProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getProductsV1ProductId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * получение продукта по id (общедоступный метод)
     * /products/v1/{productId}
     */
    async getProductsV1ProductId(requestParameters: GetProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.getProductsV1ProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создание нового продукта лейблом
     * /products/v1
     */
    async postProductsV1Raw(requestParameters: PostProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutProductsRequestToJSON(requestParameters['postPutProductsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Создание нового продукта лейблом
     * /products/v1
     */
    async postProductsV1(requestParameters: PostProductsV1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.postProductsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * редактирование продукта (для лейбла)
     * /products/v1/{productId}
     */
    async putProductsV1ProductIdRaw(requestParameters: PutProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling putProductsV1ProductId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutProductsRequestToJSON(requestParameters['postPutProductsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * редактирование продукта (для лейбла)
     * /products/v1/{productId}
     */
    async putProductsV1ProductId(requestParameters: PutProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.putProductsV1ProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
