/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * Модель данных инвестора для отображения в рейтинге
 * @export
 * @interface Investor
 */
export interface Investor {
    /**
     * ID пользователя; null, если аккаунт приватный.
     * @type {string}
     * @memberof Investor
     */
    userId: string | null;
    /**
     * Ник пользователя; ник приватного пользователя должен закрываться маской.
     * @type {string}
     * @memberof Investor
     */
    nickname: string | null;
    /**
     * Ссылка на аватар пользователя.
     * null - в случае если аккаунт приватный.
     * @type {string}
     * @memberof Investor
     */
    avatarUrl: string | null;
    /**
     * Сумма, инвестированная пользователем в минимальных базовых единицах
     * @type {number}
     * @memberof Investor
     */
    invested: number;
    /**
     * Доля приобретённых авторских прав в %
     * @type {number}
     * @memberof Investor
     */
    userShare: number;
    /**
     * 
     * @type {Currency}
     * @memberof Investor
     */
    currency: Currency;
}

/**
 * Check if a given object implements the Investor interface.
 */
export function instanceOfInvestor(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('nickname' in value)) return false;
    if (!('avatarUrl' in value)) return false;
    if (!('invested' in value)) return false;
    if (!('userShare' in value)) return false;
    if (!('currency' in value)) return false;
    return true;
}

export function InvestorFromJSON(json: any): Investor {
    return InvestorFromJSONTyped(json, false);
}

export function InvestorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Investor {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'nickname': json['nickname'],
        'avatarUrl': json['avatarUrl'],
        'invested': json['invested'],
        'userShare': json['userShare'],
        'currency': CurrencyFromJSON(json['currency']),
    };
}

export function InvestorToJSON(value?: Investor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'nickname': value['nickname'],
        'avatarUrl': value['avatarUrl'],
        'invested': value['invested'],
        'userShare': value['userShare'],
        'currency': CurrencyToJSON(value['currency']),
    };
}

