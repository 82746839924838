/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetInvestors200,
  InternalServerError500,
} from '../models/index';
import {
    GetInvestors200FromJSON,
    GetInvestors200ToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
} from '../models/index';

export interface GetInvestorsV1ProductIdRequest {
    productId: string;
    limit: number;
    offset: number;
}

/**
 * 
 */
export class InvestorsApi extends runtime.BaseAPI {

    /**
     * Получение рейтинга инвесторов по ID продукта
     * /investors/v1/{productId}
     */
    async getInvestorsV1ProductIdRaw(requestParameters: GetInvestorsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvestors200>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getInvestorsV1ProductId().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getInvestorsV1ProductId().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getInvestorsV1ProductId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/investors/v1/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvestors200FromJSON(jsonValue));
    }

    /**
     * Получение рейтинга инвесторов по ID продукта
     * /investors/v1/{productId}
     */
    async getInvestorsV1ProductId(requestParameters: GetInvestorsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvestors200> {
        const response = await this.getInvestorsV1ProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
