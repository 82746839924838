/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RolesEnum = {
    user: 'user',
    artist: 'artist',
    label: 'label'
} as const;
export type RolesEnum = typeof RolesEnum[keyof typeof RolesEnum];


export function instanceOfRolesEnum(value: any): boolean {
    return Object.values(RolesEnum).includes(value);
}

export function RolesEnumFromJSON(json: any): RolesEnum {
    return RolesEnumFromJSONTyped(json, false);
}

export function RolesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RolesEnum {
    return json as RolesEnum;
}

export function RolesEnumToJSON(value?: RolesEnum | null): any {
    return value as any;
}

